// noinspection JSUnusedGlobalSymbols,SpellCheckingInspection'
// ⛔️ DO NOT EDIT THIS FILE. It's generated from `yarn generate`.

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Date: { input: string; output: string }
  DateTime: { input: string; output: string }
  JSON: { input: any; output: any }
  Time: { input: any; output: any }
}

/** Your Daybridge user account. */
export type Account = {
  __typename?: "Account"
  avatar?: Maybe<Avatar>
  badges: Array<UserBadge>
  calendars: DaybridgeCalendarConnection
  clientData: Array<JsonKeyValuePair>
  country?: Maybe<Country>
  defaultAllDayEventAlerts: Array<AllDayEventAlert>
  defaultEventAlerts: Array<EventAlert>
  emailAddresses: EmailAddressConnection
  id: Scalars["ID"]["output"]
  joinedAt: Scalars["DateTime"]["output"]
  locale: Scalars["String"]["output"]
  migrated: Scalars["Boolean"]["output"]
  name?: Maybe<Scalars["String"]["output"]>
  oAuthConnections: OAuthConnectionConnection
  onboardingCompleted: Scalars["Boolean"]["output"]
  onboardingCompletedAt?: Maybe<Scalars["DateTime"]["output"]>
  optInToUpdates: Scalars["Boolean"]["output"]
  phoneNumbers: PhoneNumberConnection
  profileColor: Color
  providerId: OAuthProviderId
  providerName: Scalars["String"]["output"]
  timeZone?: Maybe<TimeZone>
  updatedAt: Scalars["DateTime"]["output"]
  userNumber?: Maybe<Scalars["Int"]["output"]>
  username?: Maybe<Scalars["String"]["output"]>
  weekStart: DayOfWeek
}

/** Your Daybridge user account. */
export type AccountCalendarsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

/** Your Daybridge user account. */
export type AccountEmailAddressesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

/** Your Daybridge user account. */
export type AccountOAuthConnectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

/** Your Daybridge user account. */
export type AccountPhoneNumbersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type AllDayEventAlert = {
  __typename?: "AllDayEventAlert"
  at: Scalars["Time"]["output"]
  days: Scalars["Int"]["output"]
}

export type AllDayEventAlertInput = {
  at?: InputMaybe<Scalars["Time"]["input"]>
  days?: InputMaybe<Scalars["Int"]["input"]>
}

export type AppleOAuthConnection = OAuthConnection & {
  __typename?: "AppleOAuthConnection"
  accountId: Scalars["String"]["output"]
  createdAt: Scalars["DateTime"]["output"]
  customName?: Maybe<Scalars["String"]["output"]>
  deleted: Scalars["Boolean"]["output"]
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>
  email?: Maybe<Scalars["String"]["output"]>
  id: Scalars["ID"]["output"]
  providerId: OAuthProviderId
  providerName: Scalars["String"]["output"]
  scopes: Array<Scalars["String"]["output"]>
  status: OAuthConnectionStatus
  updatedAt: Scalars["DateTime"]["output"]
}

/** Areas are used to help organize your events. */
export type Area = {
  __typename?: "Area"
  color: Color
  createdAt: Scalars["DateTime"]["output"]
  deleted: Scalars["Boolean"]["output"]
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>
  icon?: Maybe<Icon>
  id: Scalars["ID"]["output"]
  name: Scalars["String"]["output"]
  updatedAt: Scalars["DateTime"]["output"]
}

export type AreaConnection = {
  __typename?: "AreaConnection"
  edges: Array<AreaEdge>
  pageInfo: PageInfo
}

export type AreaEdge = {
  __typename?: "AreaEdge"
  cursor: Scalars["String"]["output"]
  node: Area
}

/** The authentication provider ID. */
export enum AuthProviderId {
  Apple = "apple",
  Email = "email",
  Google = "google",
}

export type Avatar = {
  __typename?: "Avatar"
  url: Scalars["String"]["output"]
}

export type AvatarInput = {
  uploadId: Scalars["String"]["input"]
}

export type Calendar = {
  account?: Maybe<CalendarAccount>
  createdAt?: Maybe<Scalars["DateTime"]["output"]>
  createdBy?: Maybe<User>
  customName?: Maybe<Scalars["String"]["output"]>
  defaultAllDayEventAlerts?: Maybe<Array<AllDayEventAlert>>
  defaultArea?: Maybe<Area>
  defaultEventAlerts?: Maybe<Array<EventAlert>>
  defaultTags?: Maybe<TagConnection>
  deleted: Scalars["Boolean"]["output"]
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>
  deletedBy?: Maybe<User>
  id: Scalars["ID"]["output"]
  name: Scalars["String"]["output"]
  participants: CalendarParticipantConnection
  timeZone?: Maybe<TimeZone>
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>
  updatedBy?: Maybe<User>
}

export type CalendarAccount = Account | GoogleOAuthConnection

export type CalendarAccountConnection = {
  __typename?: "CalendarAccountConnection"
  edges: Array<CalendarAccountEdge>
  pageInfo: PageInfo
}

export type CalendarAccountEdge = {
  __typename?: "CalendarAccountEdge"
  cursor: Scalars["String"]["output"]
  node: CalendarAccount
}

export type CalendarConnection = {
  __typename?: "CalendarConnection"
  edges: Array<CalendarEdge>
  pageInfo: PageInfo
}

export type CalendarEdge = {
  __typename?: "CalendarEdge"
  cursor: Scalars["String"]["output"]
  node: Calendar
}

export type CalendarParticipant = {
  __typename?: "CalendarParticipant"
  emailAddress?: Maybe<Scalars["String"]["output"]>
  id: Scalars["ID"]["output"]
  invitedAt?: Maybe<Scalars["DateTime"]["output"]>
  invitedBy?: Maybe<User>
  role: CalendarParticipantRole
  status: CalendarParticipantStatus
  statusUpdatedAt?: Maybe<Scalars["DateTime"]["output"]>
  user?: Maybe<User>
}

export type CalendarParticipantConnection = {
  __typename?: "CalendarParticipantConnection"
  edges: Array<CalendarParticipantEdge>
  pageInfo: PageInfo
}

export type CalendarParticipantEdge = {
  __typename?: "CalendarParticipantEdge"
  cursor: Scalars["String"]["output"]
  node: CalendarParticipant
}

export type CalendarParticipantInput = {
  id: Scalars["ID"]["input"]
  role?: InputMaybe<CalendarParticipantRole>
}

export type CalendarParticipantPatchInput = {
  delete?: InputMaybe<Scalars["Boolean"]["input"]>
  id: Scalars["ID"]["input"]
  role?: InputMaybe<CalendarParticipantRole>
  status?: InputMaybe<CalendarParticipantStatus>
}

/** The role of a participant in a calendar */
export enum CalendarParticipantRole {
  Editor = "editor",
  Owner = "owner",
  Reader = "reader",
}

/** The status of a participant in a calendar */
export enum CalendarParticipantStatus {
  Accepted = "accepted",
  Declined = "declined",
  Pending = "pending",
}

export type Call = {
  __typename?: "Call"
  url: Scalars["String"]["output"]
}

export type CallInput = {
  url?: InputMaybe<Scalars["String"]["input"]>
}

export type Color = {
  __typename?: "Color"
  hue: Scalars["Float"]["output"]
}

export type ColorInput = {
  hue: Scalars["Float"]["input"]
}

/** Contacts you can easily invite to events. */
export type Contact = {
  __typename?: "Contact"
  createdAt: Scalars["DateTime"]["output"]
  customName?: Maybe<Scalars["String"]["output"]>
  deleted: Scalars["Boolean"]["output"]
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>
  emailAddress?: Maybe<Scalars["String"]["output"]>
  favourite: Scalars["Boolean"]["output"]
  id: Scalars["ID"]["output"]
  lastInvitedAt?: Maybe<Scalars["DateTime"]["output"]>
  mutual?: Maybe<Scalars["Boolean"]["output"]>
  recent: Scalars["Boolean"]["output"]
  timesInvited: Scalars["Int"]["output"]
  updatedAt: Scalars["DateTime"]["output"]
  user?: Maybe<User>
}

export type ContactConnection = {
  __typename?: "ContactConnection"
  edges: Array<ContactEdge>
  pageInfo: PageInfo
}

/** Determines who is able to discover this account. */
export enum ContactDiscovery {
  ContactsOnly = "contacts_only",
  Everyone = "everyone",
  NoOne = "no_one",
}

export type ContactEdge = {
  __typename?: "ContactEdge"
  cursor: Scalars["String"]["output"]
  node: Contact
}

export type Coordinates = {
  __typename?: "Coordinates"
  latitude: Scalars["Float"]["output"]
  longitude: Scalars["Float"]["output"]
}

export type CoordinatesInput = {
  latitude?: InputMaybe<Scalars["Float"]["input"]>
  longitude?: InputMaybe<Scalars["Float"]["input"]>
}

export type Country = {
  __typename?: "Country"
  areaCodes: Array<Scalars["String"]["output"]>
  code: CountryCode
  coordinates: Coordinates
  dialCode: Scalars["String"]["output"]
  exampleSubscriberNumberFormat: Scalars["String"]["output"]
  exampleSubscriberNumberPlain: Scalars["String"]["output"]
  flagEmoji: Scalars["String"]["output"]
  id: Scalars["ID"]["output"]
  name: Scalars["String"]["output"]
}

/** An ISO 3166-1 alpha-2 country code */
export enum CountryCode {
  Ad = "AD",
  Ae = "AE",
  Af = "AF",
  Ag = "AG",
  Ai = "AI",
  Al = "AL",
  Am = "AM",
  Ao = "AO",
  Aq = "AQ",
  Ar = "AR",
  As = "AS",
  At = "AT",
  Au = "AU",
  Aw = "AW",
  Ax = "AX",
  Az = "AZ",
  Ba = "BA",
  Bb = "BB",
  Bd = "BD",
  Be = "BE",
  Bf = "BF",
  Bg = "BG",
  Bh = "BH",
  Bi = "BI",
  Bj = "BJ",
  Bl = "BL",
  Bm = "BM",
  Bn = "BN",
  Bo = "BO",
  Bq = "BQ",
  Br = "BR",
  Bs = "BS",
  Bt = "BT",
  Bv = "BV",
  Bw = "BW",
  By = "BY",
  Bz = "BZ",
  Ca = "CA",
  Cc = "CC",
  Cd = "CD",
  Cf = "CF",
  Cg = "CG",
  Ch = "CH",
  Ci = "CI",
  Ck = "CK",
  Cl = "CL",
  Cm = "CM",
  Cn = "CN",
  Co = "CO",
  Cr = "CR",
  Cu = "CU",
  Cv = "CV",
  Cw = "CW",
  Cx = "CX",
  Cy = "CY",
  Cz = "CZ",
  De = "DE",
  Dj = "DJ",
  Dk = "DK",
  Dm = "DM",
  Do = "DO",
  Dz = "DZ",
  Ec = "EC",
  Ee = "EE",
  Eg = "EG",
  Eh = "EH",
  Er = "ER",
  Es = "ES",
  Et = "ET",
  Fi = "FI",
  Fj = "FJ",
  Fk = "FK",
  Fm = "FM",
  Fo = "FO",
  Fr = "FR",
  Ga = "GA",
  Gb = "GB",
  Gd = "GD",
  Ge = "GE",
  Gf = "GF",
  Gg = "GG",
  Gh = "GH",
  Gi = "GI",
  Gl = "GL",
  Gm = "GM",
  Gn = "GN",
  Gp = "GP",
  Gq = "GQ",
  Gr = "GR",
  Gs = "GS",
  Gt = "GT",
  Gu = "GU",
  Gw = "GW",
  Gy = "GY",
  Hk = "HK",
  Hm = "HM",
  Hn = "HN",
  Hr = "HR",
  Ht = "HT",
  Hu = "HU",
  Id = "ID",
  Ie = "IE",
  Il = "IL",
  Im = "IM",
  In = "IN",
  Io = "IO",
  Iq = "IQ",
  Ir = "IR",
  Is = "IS",
  It = "IT",
  Je = "JE",
  Jm = "JM",
  Jo = "JO",
  Jp = "JP",
  Ke = "KE",
  Kg = "KG",
  Kh = "KH",
  Ki = "KI",
  Km = "KM",
  Kn = "KN",
  Kp = "KP",
  Kr = "KR",
  Kw = "KW",
  Ky = "KY",
  Kz = "KZ",
  La = "LA",
  Lb = "LB",
  Lc = "LC",
  Li = "LI",
  Lk = "LK",
  Lr = "LR",
  Ls = "LS",
  Lt = "LT",
  Lu = "LU",
  Lv = "LV",
  Ly = "LY",
  Ma = "MA",
  Mc = "MC",
  Md = "MD",
  Me = "ME",
  Mf = "MF",
  Mg = "MG",
  Mh = "MH",
  Mk = "MK",
  Ml = "ML",
  Mm = "MM",
  Mn = "MN",
  Mo = "MO",
  Mp = "MP",
  Mq = "MQ",
  Mr = "MR",
  Ms = "MS",
  Mt = "MT",
  Mu = "MU",
  Mv = "MV",
  Mw = "MW",
  Mx = "MX",
  My = "MY",
  Mz = "MZ",
  Na = "NA",
  Nc = "NC",
  Ne = "NE",
  Nf = "NF",
  Ng = "NG",
  Ni = "NI",
  Nl = "NL",
  No = "NO",
  Np = "NP",
  Nr = "NR",
  Nu = "NU",
  Nz = "NZ",
  Om = "OM",
  Pa = "PA",
  Pe = "PE",
  Pf = "PF",
  Pg = "PG",
  Ph = "PH",
  Pk = "PK",
  Pl = "PL",
  Pm = "PM",
  Pn = "PN",
  Pr = "PR",
  Ps = "PS",
  Pt = "PT",
  Pw = "PW",
  Py = "PY",
  Qa = "QA",
  Re = "RE",
  Ro = "RO",
  Rs = "RS",
  Ru = "RU",
  Rw = "RW",
  Sa = "SA",
  Sb = "SB",
  Sc = "SC",
  Sd = "SD",
  Se = "SE",
  Sg = "SG",
  Sh = "SH",
  Si = "SI",
  Sj = "SJ",
  Sk = "SK",
  Sl = "SL",
  Sm = "SM",
  Sn = "SN",
  So = "SO",
  Sr = "SR",
  Ss = "SS",
  St = "ST",
  Sv = "SV",
  Sx = "SX",
  Sy = "SY",
  Sz = "SZ",
  Tc = "TC",
  Td = "TD",
  Tf = "TF",
  Tg = "TG",
  Th = "TH",
  Tj = "TJ",
  Tk = "TK",
  Tl = "TL",
  Tm = "TM",
  Tn = "TN",
  To = "TO",
  Tr = "TR",
  Tt = "TT",
  Tv = "TV",
  Tw = "TW",
  Tz = "TZ",
  Ua = "UA",
  Ug = "UG",
  Um = "UM",
  Us = "US",
  Uy = "UY",
  Uz = "UZ",
  Va = "VA",
  Vc = "VC",
  Ve = "VE",
  Vg = "VG",
  Vi = "VI",
  Vn = "VN",
  Vu = "VU",
  Wf = "WF",
  Ws = "WS",
  Xk = "XK",
  Ye = "YE",
  Yt = "YT",
  Za = "ZA",
  Zm = "ZM",
  Zw = "ZW",
}

export type CountryConnection = {
  __typename?: "CountryConnection"
  edges: Array<CountryEdge>
  pageInfo: PageInfo
}

export type CountryEdge = {
  __typename?: "CountryEdge"
  cursor: Scalars["String"]["output"]
  node: Country
}

export type CreateAreaCreateInput = {
  color?: InputMaybe<ColorInput>
  icon?: InputMaybe<IconInput>
  name: Scalars["String"]["input"]
}

export type CreateAreaInput = {
  create: CreateAreaCreateInput
  idempotencyKey: Scalars["String"]["input"]
}

export type CreateCalendarCreateInput = {
  defaultAllDayEventAlerts?: InputMaybe<Array<AllDayEventAlertInput>>
  defaultArea?: InputMaybe<Scalars["ID"]["input"]>
  defaultEventAlerts?: InputMaybe<Array<EventAlertInput>>
  defaultTags?: InputMaybe<Array<Scalars["ID"]["input"]>>
  name: Scalars["String"]["input"]
  participants?: InputMaybe<Array<CalendarParticipantInput>>
  timeZone?: InputMaybe<Scalars["String"]["input"]>
}

export type CreateCalendarInput = {
  create: CreateCalendarCreateInput
  idempotencyKey: Scalars["String"]["input"]
}

export type CreateContactCreateInput = {
  customName?: InputMaybe<Scalars["String"]["input"]>
  emailAddress?: InputMaybe<Scalars["String"]["input"]>
  user?: InputMaybe<Scalars["String"]["input"]>
}

export type CreateContactInput = {
  create: CreateContactCreateInput
  idempotencyKey: Scalars["String"]["input"]
}

export type CreateEmailAddressCreateInput = {
  contactDiscovery?: InputMaybe<ContactDiscovery>
  emailAddress?: InputMaybe<Scalars["String"]["input"]>
}

export type CreateEmailAddressInput = {
  create?: InputMaybe<CreateEmailAddressCreateInput>
}

export type CreateItemCreateInput = {
  allDayEventAlerts?: InputMaybe<Array<AllDayEventAlertInput>>
  area?: InputMaybe<Scalars["ID"]["input"]>
  calendar: Scalars["ID"]["input"]
  call?: InputMaybe<CallInput>
  color?: InputMaybe<ColorInput>
  description?: InputMaybe<Scalars["String"]["input"]>
  end: DateTimeInfoInput
  eventAlerts?: InputMaybe<Array<EventAlertInput>>
  icon?: InputMaybe<IconInput>
  location?: InputMaybe<ItemLocationInput>
  participants?: InputMaybe<Array<ItemParticipantInput>>
  participantsCanEdit?: InputMaybe<Scalars["Boolean"]["input"]>
  participantsCanInvite?: InputMaybe<Scalars["Boolean"]["input"]>
  public?: InputMaybe<PublicEventInput>
  recurrence?: InputMaybe<RecurrenceInput>
  start: DateTimeInfoInput
  tags?: InputMaybe<Array<Scalars["ID"]["input"]>>
  title?: InputMaybe<Scalars["String"]["input"]>
  type?: InputMaybe<ItemType>
}

export type CreateItemInput = {
  create: CreateItemCreateInput
  idempotencyKey: Scalars["String"]["input"]
}

export type CreateLocationCreateInput = {
  customName?: InputMaybe<Scalars["String"]["input"]>
  icon?: InputMaybe<IconInput>
  place?: InputMaybe<Scalars["ID"]["input"]>
}

export type CreateLocationInput = {
  create?: InputMaybe<CreateLocationCreateInput>
  idempotencyKey?: InputMaybe<Scalars["String"]["input"]>
  suggestion?: InputMaybe<Scalars["String"]["input"]>
}

export type CreatePhoneNumberCreateInput = {
  contactDiscovery?: InputMaybe<ContactDiscovery>
  countryCode?: InputMaybe<CountryCode>
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>
}

export type CreatePhoneNumberInput = {
  create?: InputMaybe<CreatePhoneNumberCreateInput>
}

export type CreateTagCreateInput = {
  color?: InputMaybe<ColorInput>
  icon?: InputMaybe<IconInput>
  name: Scalars["String"]["input"]
}

export type CreateTagInput = {
  create: CreateTagCreateInput
  idempotencyKey: Scalars["String"]["input"]
}

export type DateTimeInfo = {
  __typename?: "DateTimeInfo"
  date?: Maybe<Scalars["Date"]["output"]>
  dateTime?: Maybe<Scalars["DateTime"]["output"]>
  timeZone?: Maybe<TimeZone>
}

export type DateTimeInfoInput = {
  date?: InputMaybe<Scalars["Date"]["input"]>
  dateTime?: InputMaybe<Scalars["DateTime"]["input"]>
  timeZone?: InputMaybe<Scalars["String"]["input"]>
}

/** The days of the week. */
export enum DayOfWeek {
  Friday = "friday",
  Monday = "monday",
  Saturday = "saturday",
  Sunday = "sunday",
  Thursday = "thursday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
}

export type DaybridgeCalendar = Calendar & {
  __typename?: "DaybridgeCalendar"
  account?: Maybe<Account>
  createdAt?: Maybe<Scalars["DateTime"]["output"]>
  createdBy?: Maybe<User>
  customName?: Maybe<Scalars["String"]["output"]>
  defaultAllDayEventAlerts?: Maybe<Array<AllDayEventAlert>>
  defaultArea?: Maybe<Area>
  defaultEventAlerts?: Maybe<Array<EventAlert>>
  defaultTags: TagConnection
  deleted: Scalars["Boolean"]["output"]
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>
  deletedBy?: Maybe<User>
  id: Scalars["ID"]["output"]
  idempotencyKey: Scalars["String"]["output"]
  name: Scalars["String"]["output"]
  participants: CalendarParticipantConnection
  timeZone?: Maybe<TimeZone>
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>
  updatedBy?: Maybe<User>
}

export type DaybridgeCalendarDefaultTagsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type DaybridgeCalendarParticipantsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type DaybridgeCalendarConnection = {
  __typename?: "DaybridgeCalendarConnection"
  edges: Array<DaybridgeCalendarEdge>
  pageInfo: PageInfo
}

export type DaybridgeCalendarEdge = {
  __typename?: "DaybridgeCalendarEdge"
  cursor: Scalars["String"]["output"]
  node: DaybridgeCalendar
}

/** The identifier of the Daybridge client. */
export enum DaybridgeClientId {
  Android = "android",
  Ios = "ios",
  Web = "web",
}

export type DeleteAreaInput = {
  id: Scalars["ID"]["input"]
}

export type DeleteCalendarInput = {
  id: Scalars["ID"]["input"]
}

export type DeleteContactInput = {
  id: Scalars["ID"]["input"]
}

export type DeleteEmailAddressInput = {
  id: Scalars["ID"]["input"]
}

export type DeleteItemInput = {
  applyTo?: InputMaybe<ItemApplyTo>
  id: Scalars["ID"]["input"]
}

export type DeleteLocationInput = {
  id: Scalars["ID"]["input"]
}

export type DeleteOAuthConnectionInput = {
  id: Scalars["ID"]["input"]
}

export type DeletePhoneNumberInput = {
  id: Scalars["ID"]["input"]
}

export type DeleteTagInput = {
  id: Scalars["ID"]["input"]
}

export type DeregisterDeviceInput = {
  token: Scalars["String"]["input"]
}

export type DeregisteredDeviceResponse = {
  __typename?: "DeregisteredDeviceResponse"
  token: Scalars["String"]["output"]
}

export type DiscoverContacts = {
  __typename?: "DiscoverContacts"
  hashedEmails?: Maybe<Array<Scalars["String"]["output"]>>
  hashedPhones?: Maybe<Array<Scalars["String"]["output"]>>
  source: DiscoverContactsSource
}

export type DiscoverContactsInput = {
  hashedEmails?: InputMaybe<Array<Scalars["String"]["input"]>>
  hashedPhones?: InputMaybe<Array<Scalars["String"]["input"]>>
  source: DiscoverContactsSource
}

/** The source of the contact */
export enum DiscoverContactsSource {
  Android = "android",
  Ios = "ios",
}

export type EmailAddress = {
  __typename?: "EmailAddress"
  contactDiscovery: ContactDiscovery
  createdAt: Scalars["DateTime"]["output"]
  deleted: Scalars["Boolean"]["output"]
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>
  emailAddress: Scalars["String"]["output"]
  id: Scalars["ID"]["output"]
  primary: Scalars["Boolean"]["output"]
  updatedAt: Scalars["DateTime"]["output"]
  verified: Scalars["Boolean"]["output"]
  verifiedAt?: Maybe<Scalars["DateTime"]["output"]>
}

export type EmailAddressConnection = {
  __typename?: "EmailAddressConnection"
  edges: Array<EmailAddressEdge>
  pageInfo: PageInfo
}

export type EmailAddressEdge = {
  __typename?: "EmailAddressEdge"
  cursor: Scalars["String"]["output"]
  node: EmailAddress
}

export type EventAlert = {
  __typename?: "EventAlert"
  minutes: Scalars["Int"]["output"]
}

export type EventAlertInput = {
  minutes?: InputMaybe<Scalars["Int"]["input"]>
}

/** The type used to categorize feedback */
export enum FeedbackType {
  AccountBilling = "account_billing",
  Bug = "bug",
  IdeaSuggestion = "idea_suggestion",
  Other = "other",
  ReportUser = "report_user",
  Security = "security",
  Translation = "translation",
}

export type FileUpload = {
  __typename?: "FileUpload"
  createdAt: Scalars["DateTime"]["output"]
  fileUrl: Scalars["String"]["output"]
  id: Scalars["ID"]["output"]
  idempotencyKey: Scalars["String"]["output"]
  mediaType: Scalars["String"]["output"]
  size: Scalars["Int"]["output"]
  status: UploadStatus
  updatedAt: Scalars["DateTime"]["output"]
  uploadType: UploadType
  uploadUrl: Scalars["String"]["output"]
  uploadUrlExpiresAt: Scalars["DateTime"]["output"]
  uploadedBy?: Maybe<User>
}

/** The frequencies supported for recurring events. */
export enum Frequency {
  Daily = "daily",
  Monthly = "monthly",
  Weekly = "weekly",
  Yearly = "yearly",
}

export type GenerateOAuthConnectUrlInput = {
  areaId?: InputMaybe<Scalars["String"]["input"]>
  providerId: OAuthProviderId
  redirectUri?: InputMaybe<Scalars["String"]["input"]>
}

export type GenerateOAuthConnectUrlResponse = {
  __typename?: "GenerateOAuthConnectUrlResponse"
  url: Scalars["String"]["output"]
}

export type GetTokenInput = {
  /** The identifier of the Daybridge client. */
  clientId: DaybridgeClientId
  /** The authorization code. */
  code: Scalars["String"]["input"]
  /** The code verifier for the PKCE flow. */
  codeVerifier?: InputMaybe<Scalars["String"]["input"]>
  /** The user's locale */
  locale: Scalars["String"]["input"]
  /** The authentication provider. */
  providerId: AuthProviderId
  /** The redirect URI. */
  redirectUri?: InputMaybe<Scalars["String"]["input"]>
}

export type GoogleCalendar = Calendar & {
  __typename?: "GoogleCalendar"
  account: GoogleOAuthConnection
  calendarId: Scalars["ID"]["output"]
  color?: Maybe<Color>
  createdAt: Scalars["DateTime"]["output"]
  createdBy?: Maybe<User>
  customName?: Maybe<Scalars["String"]["output"]>
  defaultAllDayEventAlerts?: Maybe<Array<AllDayEventAlert>>
  defaultArea?: Maybe<Area>
  defaultEventAlerts?: Maybe<Array<EventAlert>>
  defaultTags?: Maybe<TagConnection>
  deleted: Scalars["Boolean"]["output"]
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>
  deletedBy?: Maybe<User>
  enabled: Scalars["Boolean"]["output"]
  id: Scalars["ID"]["output"]
  name: Scalars["String"]["output"]
  participants: CalendarParticipantConnection
  timeZone?: Maybe<TimeZone>
  updatedAt: Scalars["DateTime"]["output"]
  updatedBy?: Maybe<User>
}

export type GoogleCalendarDefaultTagsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type GoogleCalendarParticipantsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type GoogleCalendarConnection = {
  __typename?: "GoogleCalendarConnection"
  edges: Array<GoogleCalendarEdge>
  pageInfo: PageInfo
}

export type GoogleCalendarEdge = {
  __typename?: "GoogleCalendarEdge"
  cursor: Scalars["String"]["output"]
  node: GoogleCalendar
}

export type GoogleOAuthConnection = OAuthConnection & {
  __typename?: "GoogleOAuthConnection"
  accountId: Scalars["String"]["output"]
  calendars: GoogleCalendarConnection
  createdAt: Scalars["DateTime"]["output"]
  customName?: Maybe<Scalars["String"]["output"]>
  defaultArea?: Maybe<Area>
  defaultTags: TagConnection
  deleted: Scalars["Boolean"]["output"]
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>
  email?: Maybe<Scalars["String"]["output"]>
  id: Scalars["ID"]["output"]
  providerId: OAuthProviderId
  providerName: Scalars["String"]["output"]
  scopes: Array<Scalars["String"]["output"]>
  status: OAuthConnectionStatus
  updatedAt: Scalars["DateTime"]["output"]
}

export type GoogleOAuthConnectionCalendarsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type GoogleOAuthConnectionDefaultTagsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

/** Definition of a Daybridge icon */
export type Icon = {
  __typename?: "Icon"
  id: IconId
  name: Scalars["String"]["output"]
  queryStrings: Array<Scalars["String"]["output"]>
}

export type IconConnection = {
  __typename?: "IconConnection"
  edges: Array<IconEdge>
  pageInfo: PageInfo
}

export type IconEdge = {
  __typename?: "IconEdge"
  cursor: Scalars["String"]["output"]
  node: Icon
}

/** Collection of icons that are related to a specific category or theme. */
export type IconGroup = {
  __typename?: "IconGroup"
  icon: Icon
  icons: IconConnection
  id: Scalars["ID"]["output"]
  name: Scalars["String"]["output"]
}

/** Collection of icons that are related to a specific category or theme. */
export type IconGroupIconsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type IconGroupConnection = {
  __typename?: "IconGroupConnection"
  edges: Array<IconGroupEdge>
  pageInfo: PageInfo
}

export type IconGroupEdge = {
  __typename?: "IconGroupEdge"
  cursor: Scalars["String"]["output"]
  node: IconGroup
}

/** The identifier for Daybridge icons */
export enum IconId {
  AlignCenter = "align_center",
  AlignCenterOutline = "align_center_outline",
  AlignJustify = "align_justify",
  AlignJustifyOutline = "align_justify_outline",
  AlignLeft = "align_left",
  AlignLeftOutline = "align_left_outline",
  AlignRight = "align_right",
  AlignRightOutline = "align_right_outline",
  AmericanFootball = "american_football",
  Android = "android",
  Apple = "apple",
  Area = "area",
  AreaOutline = "area_outline",
  ArrowDown = "arrow_down",
  ArrowLeft = "arrow_left",
  ArrowRight = "arrow_right",
  ArrowUp = "arrow_up",
  Art = "art",
  Atom = "atom",
  Award = "award",
  Badminton = "badminton",
  Ballet = "ballet",
  Baseball = "baseball",
  Basketball = "basketball",
  Bath = "bath",
  Bbq = "bbq",
  Beach = "beach",
  Bed = "bed",
  Bell = "bell",
  BellInactive = "bell_inactive",
  BellInactiveOutline = "bell_inactive_outline",
  BellOutline = "bell_outline",
  Bird = "bird",
  Birthday = "birthday",
  BoardGame = "board_game",
  Boat = "boat",
  Bold = "bold",
  Book = "book",
  BookOutline = "book_outline",
  BowlingPins = "bowling_pins",
  Box = "box",
  Boxing = "boxing",
  Bug = "bug",
  Bus = "bus",
  BusOutline = "bus_outline",
  Calculator = "calculator",
  Calendar = "calendar",
  CalendarOutline = "calendar_outline",
  CalendarStar = "calendar_star",
  CalendarStarOutline = "calendar_star_outline",
  Camcorder = "camcorder",
  CamcorderOutline = "camcorder_outline",
  Camera = "camera",
  CameraOutline = "camera_outline",
  Car = "car",
  Cards = "cards",
  Cash = "cash",
  Champagne = "champagne",
  ChevronDown = "chevron_down",
  ChevronLeft = "chevron_left",
  ChevronRight = "chevron_right",
  ChevronUp = "chevron_up",
  ChristmasTree = "christmas_tree",
  Class = "class",
  Cleaning = "cleaning",
  Clock = "clock",
  ClockChange = "clock_change",
  ClockOutline = "clock_outline",
  Clouds = "clouds",
  Code = "code",
  Coffee = "coffee",
  Collapse = "collapse",
  Command = "command",
  CommandOutline = "command_outline",
  Confetti = "confetti",
  Contacts = "contacts",
  ContactsOutline = "contacts_outline",
  Cooking = "cooking",
  Copy = "copy",
  CopyOutline = "copy_outline",
  Crane = "crane",
  Cricket = "cricket",
  Cross = "cross",
  Cursor = "cursor",
  CursorAlt = "cursor_alt",
  CursorAltOutline = "cursor_alt_outline",
  CursorOutline = "cursor_outline",
  Cycle = "cycle",
  Dancing = "dancing",
  Dartboard = "dartboard",
  Daybridge = "daybridge",
  Diy = "diy",
  Dog = "dog",
  Drama = "drama",
  Drawers = "drawers",
  DrawersOutline = "drawers_outline",
  Drinks = "drinks",
  Duplicate = "duplicate",
  DuplicateOutline = "duplicate_outline",
  Edit = "edit",
  EditOutline = "edit_outline",
  Electricity = "electricity",
  EllipsisHorizontal = "ellipsis_horizontal",
  EllipsisHorizontalOutline = "ellipsis_horizontal_outline",
  EllipsisVertical = "ellipsis_vertical",
  EllipsisVerticalOutline = "ellipsis_vertical_outline",
  Envelope = "envelope",
  EnvelopeOpen = "envelope_open",
  Expand = "expand",
  Eye = "eye",
  EyeInactive = "eye_inactive",
  FaceHappy = "face_happy",
  FaceHappyOutline = "face_happy_outline",
  FaceNeutral = "face_neutral",
  FaceNeutralOutline = "face_neutral_outline",
  FaceSad = "face_sad",
  FaceSadOutline = "face_sad_outline",
  FallingSnow = "falling_snow",
  FastFood = "fast_food",
  Filter = "filter",
  FilterOutline = "filter_outline",
  FilterStack = "filter_stack",
  FilterStackOutline = "filter_stack_outline",
  Fireworks = "fireworks",
  Fishing = "fishing",
  Flag = "flag",
  Flight = "flight",
  FlightOutline = "flight_outline",
  FuelPump = "fuel_pump",
  Gas = "gas",
  General = "general",
  Gift = "gift",
  GiftOutline = "gift_outline",
  Glasses = "glasses",
  Globe = "globe",
  GoKart = "go_kart",
  Golf = "golf",
  Google = "google",
  Graduation = "graduation",
  GrandPiano = "grand_piano",
  Groceries = "groceries",
  GroceryCart = "grocery_cart",
  Guitar = "guitar",
  Gym = "gym",
  Hairdryer = "hairdryer",
  Halloween = "halloween",
  Handbag = "handbag",
  HandleHorizontal = "handle_horizontal",
  HandleVertical = "handle_vertical",
  Hiking = "hiking",
  Hockey = "hockey",
  Home = "home",
  IceSkate = "ice_skate",
  ImageSticky = "image_sticky",
  ImageStickyOutline = "image_sticky_outline",
  Immigration = "immigration",
  Inbox = "inbox",
  InboxOutline = "inbox_outline",
  Infinity = "infinity",
  InfinityOutline = "infinity_outline",
  Information = "information",
  InformationOutline = "information_outline",
  Invite = "invite",
  InviteOutline = "invite_outline",
  Italic = "italic",
  Key = "key",
  Keyboard = "keyboard",
  KeyboardOutline = "keyboard_outline",
  KnifeFork = "knife_fork",
  Lab = "lab",
  Language = "language",
  LanguageOutline = "language_outline",
  Laptop = "laptop",
  Layout = "layout",
  LayoutOutline = "layout_outline",
  Lectern = "lectern",
  LegalScales = "legal_scales",
  LifeRing = "life_ring",
  LightAircraft = "light_aircraft",
  Lightning = "lightning",
  Link = "link",
  Makeup = "makeup",
  Map = "map",
  MapOutline = "map_outline",
  MapPin = "map_pin",
  MartialArts = "martial_arts",
  Meditation = "meditation",
  Menu = "menu",
  Microphone = "microphone",
  Minus = "minus",
  Mountains = "mountains",
  Movies = "movies",
  Museum = "museum",
  MusicNotes = "music_notes",
  MuteMicrophone = "mute_microphone",
  NavigationArrow = "navigation_arrow",
  Night = "night",
  NightOutline = "night_outline",
  Note = "note",
  NoteOutline = "note_outline",
  Notification = "notification",
  OfficeBuilding = "office_building",
  Open = "open",
  PadlockLocked = "padlock_locked",
  PadlockLockedOutline = "padlock_locked_outline",
  PadlockUnlocked = "padlock_unlocked",
  PadlockUnlockedOutline = "padlock_unlocked_outline",
  Paper = "paper",
  Paperclip = "paperclip",
  Parachute = "parachute",
  ParentChild = "parent_child",
  Passport = "passport",
  Pause = "pause",
  Pawprint = "pawprint",
  Pencil = "pencil",
  People = "people",
  Person = "person",
  PersonRowing = "person_rowing",
  Phone = "phone",
  PingPong = "ping_pong",
  PlanPlus = "plan_plus",
  PlanPlusOutline = "plan_plus_outline",
  PlanPro = "plan_pro",
  PlanProOutline = "plan_pro_outline",
  Plant = "plant",
  Play = "play",
  Plus = "plus",
  Polaroid = "polaroid",
  Praying = "praying",
  Printer = "printer",
  ProtestSigns = "protest_signs",
  QuestionMark = "question_mark",
  RaceFlag = "race_flag",
  Rain = "rain",
  Reading = "reading",
  Repeat = "repeat",
  Reply = "reply",
  ReplyOutline = "reply_outline",
  Ring = "ring",
  RollerSkate = "roller_skate",
  Rollercoaster = "rollercoaster",
  Roses = "roses",
  Running = "running",
  RunningHorse = "running_horse",
  Sailboat = "sailboat",
  Scissors = "scissors",
  Search = "search",
  Send = "send",
  Settings = "settings",
  Share = "share",
  ShareOutline = "share_outline",
  Shelves = "shelves",
  ShelvesOutline = "shelves_outline",
  Shopfront = "shopfront",
  Shower = "shower",
  SidebarLeft = "sidebar_left",
  SidebarLeftOutline = "sidebar_left_outline",
  SidebarRight = "sidebar_right",
  SidebarRightOutline = "sidebar_right_outline",
  SignIn = "sign_in",
  SignOut = "sign_out",
  Skateboard = "skateboard",
  Skiing = "skiing",
  Sleep = "sleep",
  Smartphone = "smartphone",
  Snowflake = "snowflake",
  Soccer = "soccer",
  Spa = "spa",
  Spade = "spade",
  SpeechBubble = "speech_bubble",
  SpeechBubbleOutline = "speech_bubble_outline",
  Star = "star",
  StarOutline = "star_outline",
  Stethoscope = "stethoscope",
  Stop = "stop",
  StopOutline = "stop_outline",
  Strikethrough = "strikethrough",
  Suitcase = "suitcase",
  Sun = "sun",
  SunOutline = "sun_outline",
  Sunrise = "sunrise",
  SunriseOutline = "sunrise_outline",
  Sunset = "sunset",
  SunsetOutline = "sunset_outline",
  Surfboard = "surfboard",
  Swatch = "swatch",
  SwatchOutline = "swatch_outline",
  Swimming = "swimming",
  TShirt = "t_shirt",
  Tag = "tag",
  TagOutline = "tag_outline",
  Taxi = "taxi",
  Tennis = "tennis",
  Theme = "theme",
  ThoughtBubble = "thought_bubble",
  Tick = "tick",
  Today = "today",
  TodayOutline = "today_outline",
  Todo = "todo",
  Tooth = "tooth",
  Train = "train",
  TrashEmpty = "trash_empty",
  TrashFull = "trash_full",
  Tree = "tree",
  TrendDown = "trend_down",
  TrendDownOutline = "trend_down_outline",
  TrendUp = "trend_up",
  TrendUpOutline = "trend_up_outline",
  Trophy = "trophy",
  Truck = "truck",
  Tv = "tv",
  Twitter = "twitter",
  Underline = "underline",
  Undo = "undo",
  UndoOutline = "undo_outline",
  User = "user",
  UserOutline = "user_outline",
  Users = "users",
  UsersOutline = "users_outline",
  Vaccination = "vaccination",
  VideoGames = "video_games",
  ViewAgenda = "view_agenda",
  ViewAgendaOutline = "view_agenda_outline",
  ViewDay = "view_day",
  ViewDayOutline = "view_day_outline",
  ViewMonth = "view_month",
  ViewMonthOutline = "view_month_outline",
  ViewWeek = "view_week",
  ViewWeekOutline = "view_week_outline",
  Views = "views",
  ViewsOutline = "views_outline",
  Violin = "violin",
  Volunteering = "volunteering",
  Voting = "voting",
  Walk = "walk",
  Warning = "warning",
  WavingHand = "waving_hand",
  WeddingCake = "wedding_cake",
  Wheelchair = "wheelchair",
  Wind = "wind",
  World = "world",
  Yarn = "yarn",
  Yoga = "yoga",
}

export type IconInput = {
  id: IconId
}

export type Item = {
  __typename?: "Item"
  allDayEventAlerts?: Maybe<Array<AllDayEventAlert>>
  area?: Maybe<Area>
  calendar: Calendar
  call?: Maybe<Call>
  canceled: Scalars["Boolean"]["output"]
  canceledAt?: Maybe<Scalars["DateTime"]["output"]>
  canceledBy?: Maybe<User>
  createdAt: Scalars["DateTime"]["output"]
  createdBy?: Maybe<User>
  debug?: Maybe<Array<JsonKeyValuePair>>
  deleted: Scalars["Boolean"]["output"]
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>
  deletedBy?: Maybe<User>
  description?: Maybe<Scalars["String"]["output"]>
  end: DateTimeInfo
  eventAlerts?: Maybe<Array<EventAlert>>
  icon?: Maybe<Icon>
  id: Scalars["ID"]["output"]
  idempotencyKey?: Maybe<Scalars["ID"]["output"]>
  location?: Maybe<ItemLocation>
  parent?: Maybe<Item>
  participants: ItemParticipantConnection
  participantsCanEdit?: Maybe<Scalars["Boolean"]["output"]>
  participantsCanInvite?: Maybe<Scalars["Boolean"]["output"]>
  properties?: Maybe<ItemProperties>
  public?: Maybe<PublicEvent>
  recurrence?: Maybe<Recurrence>
  recurring?: Maybe<Scalars["Boolean"]["output"]>
  start: DateTimeInfo
  tags?: Maybe<TagConnection>
  title?: Maybe<Scalars["String"]["output"]>
  type: ItemType
  updatedAt: Scalars["DateTime"]["output"]
  updatedBy?: Maybe<User>
}

export type ItemParticipantsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type ItemTagsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

/** The different ways to apply a operation to a recurring item */
export enum ItemApplyTo {
  AllInstances = "all_instances",
  ThisAndFuture = "this_and_future",
  ThisInstanceOnly = "this_instance_only",
}

export type ItemConnection = {
  __typename?: "ItemConnection"
  edges: Array<ItemEdge>
  pageInfo: PageInfo
}

export type ItemEdge = {
  __typename?: "ItemEdge"
  cursor: Scalars["String"]["output"]
  node: Item
}

export type ItemLocation = {
  __typename?: "ItemLocation"
  location?: Maybe<Location>
  place?: Maybe<Place>
  text?: Maybe<Scalars["String"]["output"]>
}

export type ItemLocationInput = {
  /** The ID of the Place */
  place?: InputMaybe<Scalars["String"]["input"]>
  /** The display name of the plaintext location */
  text?: InputMaybe<Scalars["String"]["input"]>
}

export type ItemParticipant = {
  __typename?: "ItemParticipant"
  canEdit?: Maybe<Scalars["Boolean"]["output"]>
  canInvite?: Maybe<Scalars["Boolean"]["output"]>
  displayName?: Maybe<Scalars["String"]["output"]>
  emailAddress?: Maybe<Scalars["String"]["output"]>
  id: Scalars["ID"]["output"]
  invitedAt?: Maybe<Scalars["DateTime"]["output"]>
  invitedBy?: Maybe<User>
  message?: Maybe<Scalars["String"]["output"]>
  optional: Scalars["Boolean"]["output"]
  respondedAt?: Maybe<Scalars["DateTime"]["output"]>
  status: ItemParticipantStatus
  user?: Maybe<User>
}

export type ItemParticipantConnection = {
  __typename?: "ItemParticipantConnection"
  edges: Array<ItemParticipantEdge>
  pageInfo: PageInfo
}

export type ItemParticipantEdge = {
  __typename?: "ItemParticipantEdge"
  cursor: Scalars["String"]["output"]
  node: ItemParticipant
}

export type ItemParticipantInput = {
  canEdit?: InputMaybe<Scalars["Boolean"]["input"]>
  canInvite?: InputMaybe<Scalars["Boolean"]["input"]>
  emailAddress?: InputMaybe<Scalars["String"]["input"]>
  optional?: InputMaybe<Scalars["Boolean"]["input"]>
  user?: InputMaybe<Scalars["ID"]["input"]>
}

export type ItemParticipantPatchInput = {
  canEdit?: InputMaybe<Scalars["Boolean"]["input"]>
  canInvite?: InputMaybe<Scalars["Boolean"]["input"]>
  delete?: InputMaybe<Scalars["Boolean"]["input"]>
  emailAddress?: InputMaybe<Scalars["String"]["input"]>
  id?: InputMaybe<Scalars["ID"]["input"]>
  message?: InputMaybe<Scalars["String"]["input"]>
  optional?: InputMaybe<Scalars["Boolean"]["input"]>
  status?: InputMaybe<ItemParticipantStatus>
  user?: InputMaybe<Scalars["ID"]["input"]>
}

/** The status of a participant in an item */
export enum ItemParticipantStatus {
  Accepted = "accepted",
  Declined = "declined",
  Pending = "pending",
  Tentative = "tentative",
}

export type ItemProperties = TaskProperties

/** The supported item types. */
export enum ItemType {
  Block = "block",
  Event = "event",
  Marker = "marker",
  Note = "note",
  Task = "task",
  Travel = "travel",
}

export type JsonKeyValuePair = {
  __typename?: "JsonKeyValuePair"
  key: Scalars["String"]["output"]
  value?: Maybe<Scalars["JSON"]["output"]>
}

export type JsonKeyValuePairInput = {
  key: Scalars["String"]["input"]
  value?: InputMaybe<Scalars["JSON"]["input"]>
}

export type LinkUserInput = {
  /** The invitation token to link to the authenticated account. */
  invitationToken: Scalars["String"]["input"]
}

export type Location = {
  __typename?: "Location"
  createdAt: Scalars["DateTime"]["output"]
  customName?: Maybe<Scalars["String"]["output"]>
  deleted: Scalars["Boolean"]["output"]
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>
  icon?: Maybe<Icon>
  id: Scalars["ID"]["output"]
  place?: Maybe<Place>
  suggested: Scalars["Boolean"]["output"]
  updatedAt: Scalars["DateTime"]["output"]
}

export type LocationConnection = {
  __typename?: "LocationConnection"
  edges: Array<LocationEdge>
  pageInfo: PageInfo
}

export type LocationEdge = {
  __typename?: "LocationEdge"
  cursor: Scalars["String"]["output"]
  node: Location
}

export type MarkNotificationsReadResponse = {
  __typename?: "MarkNotificationsReadResponse"
  lastReadAt: Scalars["DateTime"]["output"]
}

export type Mutation = {
  __typename?: "Mutation"
  /** Create a new area. */
  createArea?: Maybe<Area>
  /** Create a new calendar. */
  createCalendar?: Maybe<Calendar>
  /** Create a new contact. */
  createContact?: Maybe<Contact>
  createEmailAddress?: Maybe<EmailAddress>
  /** Create a new item. */
  createItem?: Maybe<Item>
  /** Add a location to your saved locations. */
  createLocation?: Maybe<Location>
  createPhoneNumber?: Maybe<PhoneNumber>
  /** Create a new tag. */
  createTag?: Maybe<Tag>
  /** Delete your Daybridge account. */
  deleteAccount?: Maybe<Account>
  /** Delete a area. */
  deleteArea?: Maybe<Area>
  /** Delete a calendar. */
  deleteCalendar?: Maybe<Calendar>
  /** Delete a contact. */
  deleteContact?: Maybe<Contact>
  /** Delete an email address from your Daybridge account. */
  deleteEmailAddress?: Maybe<EmailAddress>
  /** Delete an item. */
  deleteItem?: Maybe<Item>
  /** Delete a location. */
  deleteLocation?: Maybe<Location>
  /** Deletes an OAuth connection. */
  deleteOAuthConnection?: Maybe<OAuthConnection>
  /** Delete a phone number from your Daybridge account. */
  deletePhoneNumber?: Maybe<PhoneNumber>
  /** Delete a tag. */
  deleteTag?: Maybe<Tag>
  deregisterDevice?: Maybe<DeregisteredDeviceResponse>
  discoverContacts?: Maybe<DiscoverContacts>
  generateOAuthConnectUrl?: Maybe<GenerateOAuthConnectUrlResponse>
  getToken?: Maybe<TokenSetResponse>
  /** Link a pending user to an existing account using an invitation token. */
  linkUser?: Maybe<Account>
  /** Mark all notifications as read. */
  markNotificationsRead?: Maybe<MarkNotificationsReadResponse>
  refreshToken?: Maybe<TokenSetResponse>
  registerDevice?: Maybe<RegisterDeviceResponse>
  /** Reorder an existing area. */
  reorderAreas?: Maybe<Scalars["Boolean"]["output"]>
  /** Reorder an existing calendar account. */
  reorderCalendarAccounts?: Maybe<Scalars["Boolean"]["output"]>
  /** Reorder an existing calendar. */
  reorderCalendars?: Maybe<Scalars["Boolean"]["output"]>
  /** Reorder an existing location. */
  reorderLocations?: Maybe<Scalars["Boolean"]["output"]>
  /** Reorder an existing tag. */
  reorderTags?: Maybe<Scalars["Boolean"]["output"]>
  resendEmailAddressVerification?: Maybe<EmailAddress>
  resendPhoneNumberVerification?: Maybe<PhoneNumber>
  /** Forces a resync of an OAuth connection */
  resyncOAuthConnection?: Maybe<OAuthConnection>
  sendFeedback?: Maybe<SendFeedbackResponse>
  /** Set the user's current location bias. */
  setCurrentLocationBias?: Maybe<Coordinates>
  signInWithEmail?: Maybe<SignInWithEmailResponse>
  submitOAuthConnectReturnUrl?: Maybe<OAuthConnection>
  /** Update your user profile and manage your account settings. */
  updateAccount?: Maybe<Account>
  /** Update an existing area. */
  updateArea?: Maybe<Area>
  /** Update an existing calendar. */
  updateCalendar?: Maybe<Calendar>
  /** Update an existing contact. */
  updateContact?: Maybe<Contact>
  updateEmailAddress?: Maybe<EmailAddress>
  /** Update an existing item. */
  updateItem?: Maybe<Item>
  /** Update an existing location. */
  updateLocation?: Maybe<Location>
  updateOAuthConnection?: Maybe<OAuthConnection>
  updatePhoneNumber?: Maybe<PhoneNumber>
  /** Update an existing tag. */
  updateTag?: Maybe<Tag>
  uploadFile?: Maybe<FileUpload>
  verifyEmailAddress?: Maybe<EmailAddress>
  verifyPhoneNumber?: Maybe<PhoneNumber>
}

export type MutationCreateAreaArgs = {
  input: CreateAreaInput
}

export type MutationCreateCalendarArgs = {
  input: CreateCalendarInput
}

export type MutationCreateContactArgs = {
  input: CreateContactInput
}

export type MutationCreateEmailAddressArgs = {
  input: CreateEmailAddressInput
}

export type MutationCreateItemArgs = {
  input: CreateItemInput
}

export type MutationCreateLocationArgs = {
  input: CreateLocationInput
}

export type MutationCreatePhoneNumberArgs = {
  input: CreatePhoneNumberInput
}

export type MutationCreateTagArgs = {
  input: CreateTagInput
}

export type MutationDeleteAreaArgs = {
  input: DeleteAreaInput
}

export type MutationDeleteCalendarArgs = {
  input: DeleteCalendarInput
}

export type MutationDeleteContactArgs = {
  input: DeleteContactInput
}

export type MutationDeleteEmailAddressArgs = {
  input: DeleteEmailAddressInput
}

export type MutationDeleteItemArgs = {
  input: DeleteItemInput
}

export type MutationDeleteLocationArgs = {
  input: DeleteLocationInput
}

export type MutationDeleteOAuthConnectionArgs = {
  input: DeleteOAuthConnectionInput
}

export type MutationDeletePhoneNumberArgs = {
  input: DeletePhoneNumberInput
}

export type MutationDeleteTagArgs = {
  input: DeleteTagInput
}

export type MutationDeregisterDeviceArgs = {
  input: DeregisterDeviceInput
}

export type MutationDiscoverContactsArgs = {
  input: DiscoverContactsInput
}

export type MutationGenerateOAuthConnectUrlArgs = {
  input: GenerateOAuthConnectUrlInput
}

export type MutationGetTokenArgs = {
  input: GetTokenInput
}

export type MutationLinkUserArgs = {
  input: LinkUserInput
}

export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput
}

export type MutationRegisterDeviceArgs = {
  input: RegisterDeviceInput
}

export type MutationReorderAreasArgs = {
  input: ReorderAreasInput
}

export type MutationReorderCalendarAccountsArgs = {
  input: ReorderCalendarAccountsInput
}

export type MutationReorderCalendarsArgs = {
  input: ReorderCalendarsInput
}

export type MutationReorderLocationsArgs = {
  input: ReorderLocationsInput
}

export type MutationReorderTagsArgs = {
  input: ReorderTagsInput
}

export type MutationResendEmailAddressVerificationArgs = {
  input: ResendEmailAddressVerificationInput
}

export type MutationResendPhoneNumberVerificationArgs = {
  input: ResendPhoneNumberVerificationInput
}

export type MutationResyncOAuthConnectionArgs = {
  input: ResyncOAuthConnectionInput
}

export type MutationSendFeedbackArgs = {
  input: SendFeedbackInput
}

export type MutationSetCurrentLocationBiasArgs = {
  input?: InputMaybe<CoordinatesInput>
}

export type MutationSignInWithEmailArgs = {
  input: SignInWithEmailInput
}

export type MutationSubmitOAuthConnectReturnUrlArgs = {
  input: SubmitOAuthConnectReturnUrlInput
}

export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput
}

export type MutationUpdateAreaArgs = {
  input: UpdateAreaInput
}

export type MutationUpdateCalendarArgs = {
  input: UpdateCalendarInput
}

export type MutationUpdateContactArgs = {
  input: UpdateContactInput
}

export type MutationUpdateEmailAddressArgs = {
  input: UpdateEmailAddressInput
}

export type MutationUpdateItemArgs = {
  input: UpdateItemInput
}

export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput
}

export type MutationUpdateOAuthConnectionArgs = {
  input: UpdateOAuthConnectionInput
}

export type MutationUpdatePhoneNumberArgs = {
  input: UpdatePhoneNumberInput
}

export type MutationUpdateTagArgs = {
  input: UpdateTagInput
}

export type MutationUploadFileArgs = {
  input: UploadFileInput
}

export type MutationVerifyEmailAddressArgs = {
  input: VerifyEmailAddressInput
}

export type MutationVerifyPhoneNumberArgs = {
  input: VerifyPhoneNumberInput
}

/** A notification to display in the app's notification center. */
export type Notification = {
  __typename?: "Notification"
  body?: Maybe<Scalars["String"]["output"]>
  color?: Maybe<Color>
  createdAt: Scalars["DateTime"]["output"]
  icon?: Maybe<Icon>
  id: Scalars["ID"]["output"]
  idempotencyKey: Scalars["String"]["output"]
  imageStyle?: Maybe<NotificationImageStyle>
  imageUrl?: Maybe<Scalars["String"]["output"]>
  read: Scalars["Boolean"]["output"]
  title?: Maybe<Scalars["String"]["output"]>
  updatedAt: Scalars["DateTime"]["output"]
}

export type NotificationConnection = {
  __typename?: "NotificationConnection"
  edges: Array<NotificationEdge>
  pageInfo: PageInfo
}

export type NotificationEdge = {
  __typename?: "NotificationEdge"
  cursor: Scalars["String"]["output"]
  node: Notification
}

/** The style of the image to display in the notification. */
export enum NotificationImageStyle {
  Circle = "circle",
  None = "none",
  Rounded = "rounded",
}

export type OAuthConnection = {
  accountId: Scalars["String"]["output"]
  createdAt: Scalars["DateTime"]["output"]
  customName?: Maybe<Scalars["String"]["output"]>
  deleted: Scalars["Boolean"]["output"]
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>
  email?: Maybe<Scalars["String"]["output"]>
  id: Scalars["ID"]["output"]
  providerId: OAuthProviderId
  providerName: Scalars["String"]["output"]
  scopes: Array<Scalars["String"]["output"]>
  status: OAuthConnectionStatus
  updatedAt: Scalars["DateTime"]["output"]
}

export type OAuthConnectionConnection = {
  __typename?: "OAuthConnectionConnection"
  edges: Array<OAuthConnectionEdge>
  pageInfo: PageInfo
}

export type OAuthConnectionEdge = {
  __typename?: "OAuthConnectionEdge"
  cursor: Scalars["String"]["output"]
  node: OAuthConnection
}

/** The different statuses an OAuth connection can be in. */
export enum OAuthConnectionStatus {
  Connected = "connected",
  Creating = "creating",
  Deleting = "deleting",
  InsufficientScopes = "insufficient_scopes",
  RequiresReconnection = "requires_reconnection",
  Syncing = "syncing",
}

/** The OAuth connection provider ID. */
export enum OAuthProviderId {
  Apple = "apple",
  Daybridge = "daybridge",
  Google = "google",
}

export type PageInfo = {
  __typename?: "PageInfo"
  endCursor?: Maybe<Scalars["String"]["output"]>
  hasNextPage: Scalars["Boolean"]["output"]
  hasPreviousPage: Scalars["Boolean"]["output"]
  startCursor?: Maybe<Scalars["String"]["output"]>
}

export type PhoneNumber = {
  __typename?: "PhoneNumber"
  contactDiscovery: ContactDiscovery
  countryCode: Scalars["String"]["output"]
  createdAt: Scalars["DateTime"]["output"]
  deleted: Scalars["Boolean"]["output"]
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>
  id: Scalars["ID"]["output"]
  nationalFormat: Scalars["String"]["output"]
  phoneNumber: Scalars["String"]["output"]
  updatedAt: Scalars["DateTime"]["output"]
  verified: Scalars["Boolean"]["output"]
  verifiedAt?: Maybe<Scalars["DateTime"]["output"]>
}

export type PhoneNumberConnection = {
  __typename?: "PhoneNumberConnection"
  edges: Array<PhoneNumberEdge>
  pageInfo: PageInfo
}

export type PhoneNumberEdge = {
  __typename?: "PhoneNumberEdge"
  cursor: Scalars["String"]["output"]
  node: PhoneNumber
}

export type Place = {
  __typename?: "Place"
  coordinates?: Maybe<Coordinates>
  displayName: Scalars["String"]["output"]
  formattedAddress?: Maybe<Scalars["String"]["output"]>
  googleMapsUri?: Maybe<Scalars["String"]["output"]>
  id: Scalars["ID"]["output"]
  secondaryText: Scalars["String"]["output"]
  shortFormattedAddress?: Maybe<Scalars["String"]["output"]>
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>
  viewport?: Maybe<Viewport>
}

export type PlaceConnection = {
  __typename?: "PlaceConnection"
  edges: Array<PlaceEdge>
  pageInfo: PageInfo
}

export type PlaceEdge = {
  __typename?: "PlaceEdge"
  cursor: Scalars["String"]["output"]
  node: Place
}

/** The device platform, for push notifications. */
export enum Platform {
  Android = "android",
  Ios = "ios",
}

export type PublicEvent = {
  __typename?: "PublicEvent"
  color: Color
  id: Scalars["ID"]["output"]
}

export type PublicEventInput = {
  color?: InputMaybe<ColorInput>
}

export type PublicEventPatchInput = {
  color?: InputMaybe<ColorInput>
}

export type Query = {
  __typename?: "Query"
  /** Get a area by ID. */
  area?: Maybe<Area>
  /** List your areas. */
  areas?: Maybe<AreaConnection>
  /** Get a calendar by ID. */
  calendar?: Maybe<Calendar>
  /** List your calendar accounts. */
  calendarAccounts?: Maybe<CalendarAccountConnection>
  /** List your calendars. */
  calendars?: Maybe<CalendarConnection>
  /** Get a contact by ID. */
  contact?: Maybe<Contact>
  /** List your contacts. */
  contacts?: Maybe<ContactConnection>
  /** List of available countries and regions */
  countries?: Maybe<CountryConnection>
  /** Get your user profile and account settings. */
  currentAccount?: Maybe<Account>
  /** List of available icons grouped by category or theme. */
  iconGroups?: Maybe<IconGroupConnection>
  item?: Maybe<Item>
  items?: Maybe<ItemConnection>
  lambda?: Maybe<Scalars["String"]["output"]>
  /** Get a location by ID. */
  location?: Maybe<Location>
  /** List your locations. */
  locations?: Maybe<LocationConnection>
  /** Retrieve notifications for the in-app notification center. */
  notifications?: Maybe<NotificationConnection>
  place?: Maybe<Place>
  /** Search for locations to add to items. */
  searchLocations?: Maybe<SearchLocationResultConnection>
  /** Search for people to add to calendars and events. */
  searchParticipants?: Maybe<SearchParticipantResultConnection>
  /** Search for a place to add to your saved locations. */
  searchPlaces?: Maybe<PlaceConnection>
  /** Search for a user to add to your contacts. */
  searchUsers?: Maybe<UserConnection>
  /** Get a tag by ID. */
  tag?: Maybe<Tag>
  /** List your tags. */
  tags?: Maybe<TagConnection>
  /** List of available time zones */
  timeZones?: Maybe<TimeZoneConnection>
  /** Get a Daybridge user by ID or username. */
  user?: Maybe<User>
}

export type QueryAreaArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>
}

export type QueryAreasArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type QueryCalendarArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>
}

export type QueryCalendarAccountsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type QueryCalendarsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type QueryContactArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>
}

export type QueryContactsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type QueryCountriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type QueryIconGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type QueryItemArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>
}

export type QueryItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  endingAfter?: InputMaybe<Scalars["DateTime"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
  startingBefore?: InputMaybe<Scalars["DateTime"]["input"]>
  timeZone?: InputMaybe<Scalars["String"]["input"]>
}

export type QueryLocationArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>
}

export type QueryLocationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type QueryNotificationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type QueryPlaceArgs = {
  id: Scalars["ID"]["input"]
  sessionToken: Scalars["String"]["input"]
}

export type QuerySearchLocationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
  query?: InputMaybe<Scalars["String"]["input"]>
  sessionToken: Scalars["String"]["input"]
}

export type QuerySearchParticipantsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
  query?: InputMaybe<Scalars["String"]["input"]>
}

export type QuerySearchPlacesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
  query?: InputMaybe<Scalars["String"]["input"]>
  sessionToken: Scalars["String"]["input"]
}

export type QuerySearchUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
  query?: InputMaybe<Scalars["String"]["input"]>
}

export type QueryTagArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>
}

export type QueryTagsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type QueryTimeZonesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type QueryUserArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>
  username?: InputMaybe<Scalars["String"]["input"]>
}

export type Recurrence = {
  __typename?: "Recurrence"
  description: Scalars["String"]["output"]
  rules: Array<RecurrenceRule>
}

export type RecurrenceInput = {
  rules: Array<RecurrenceRuleInput>
}

export type RecurrenceRule = {
  __typename?: "RecurrenceRule"
  count?: Maybe<Scalars["Int"]["output"]>
  days?: Maybe<Array<RecurrenceRuleDay>>
  frequency: Frequency
  interval: Scalars["Int"]["output"]
  until?: Maybe<Scalars["Date"]["output"]>
}

export type RecurrenceRuleDay = {
  __typename?: "RecurrenceRuleDay"
  day: DayOfWeek
  n?: Maybe<Scalars["Int"]["output"]>
}

export type RecurrenceRuleDayInput = {
  day: DayOfWeek
  n?: InputMaybe<Scalars["Int"]["input"]>
}

export type RecurrenceRuleInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>
  days?: InputMaybe<Array<RecurrenceRuleDayInput>>
  frequency: Frequency
  interval?: InputMaybe<Scalars["Int"]["input"]>
  until?: InputMaybe<Scalars["Date"]["input"]>
}

export type RefreshTokenInput = {
  /** Your refresh token. */
  refreshToken: Scalars["String"]["input"]
}

export type RegisterDeviceInput = {
  deviceName?: InputMaybe<Scalars["String"]["input"]>
  isSandbox?: InputMaybe<Scalars["Boolean"]["input"]>
  platform: Platform
  token: Scalars["String"]["input"]
}

export type RegisterDeviceResponse = {
  __typename?: "RegisterDeviceResponse"
  deviceName?: Maybe<Scalars["String"]["output"]>
  isSandbox?: Maybe<Scalars["Boolean"]["output"]>
  platform: Platform
  token: Scalars["String"]["output"]
}

export type ReorderAreasInput = {
  sortOrder: Array<Scalars["ID"]["input"]>
}

export type ReorderCalendarAccountsInput = {
  sortOrder: Array<Scalars["ID"]["input"]>
}

export type ReorderCalendarsInput = {
  calendarAccount: Scalars["String"]["input"]
  sortOrder: Array<Scalars["ID"]["input"]>
}

export type ReorderLocationsInput = {
  sortOrder: Array<Scalars["ID"]["input"]>
}

export type ReorderTagsInput = {
  sortOrder: Array<Scalars["ID"]["input"]>
}

export type ResendEmailAddressVerificationInput = {
  id: Scalars["ID"]["input"]
}

export type ResendPhoneNumberVerificationInput = {
  id: Scalars["ID"]["input"]
}

export type ResyncOAuthConnectionInput = {
  id: Scalars["ID"]["input"]
}

export type SearchLocationResult = Location | Place

export type SearchLocationResultConnection = {
  __typename?: "SearchLocationResultConnection"
  edges: Array<SearchLocationResultEdge>
  pageInfo: PageInfo
}

export type SearchLocationResultEdge = {
  __typename?: "SearchLocationResultEdge"
  cursor: Scalars["String"]["output"]
  node: SearchLocationResult
}

export type SearchParticipantResult = Contact | User

export type SearchParticipantResultConnection = {
  __typename?: "SearchParticipantResultConnection"
  edges: Array<SearchParticipantResultEdge>
  pageInfo: PageInfo
}

export type SearchParticipantResultEdge = {
  __typename?: "SearchParticipantResultEdge"
  cursor: Scalars["String"]["output"]
  node: SearchParticipantResult
}

export type SendFeedbackInput = {
  feedbackType: FeedbackType
  idempotencyKey: Scalars["String"]["input"]
  message: Scalars["String"]["input"]
  metadata?: InputMaybe<Scalars["JSON"]["input"]>
  uploadIds?: InputMaybe<Array<Scalars["ID"]["input"]>>
}

export type SendFeedbackResponse = {
  __typename?: "SendFeedbackResponse"
  createdAt: Scalars["String"]["output"]
  feedbackType: FeedbackType
  id: Scalars["ID"]["output"]
  message: Scalars["String"]["output"]
  metadata: Scalars["JSON"]["output"]
  updatedAt: Scalars["String"]["output"]
}

export type SignInWithEmailInput = {
  /** The identifier of the Daybridge client. */
  clientId: DaybridgeClientId
  /** A code challenge for the PKCE flow. */
  codeChallenge: Scalars["String"]["input"]
  /** The email address to sign in with. */
  email: Scalars["String"]["input"]
  /** The locale to use for the sign-in email. */
  locale: Scalars["String"]["input"]
  /** The nonce parameter */
  nonce: Scalars["String"]["input"]
  /** The redirect URI. Must match approved redirect URIs. */
  redirectUri: Scalars["String"]["input"]
  /** The state parameter */
  state?: InputMaybe<Scalars["String"]["input"]>
}

export type SignInWithEmailResponse = {
  __typename?: "SignInWithEmailResponse"
  email: Scalars["String"]["output"]
}

export type SubmitOAuthConnectReturnUrlInput = {
  returnUrl: Scalars["String"]["input"]
}

/** Tags are used to help search and filter your events. */
export type Tag = {
  __typename?: "Tag"
  color: Color
  createdAt: Scalars["DateTime"]["output"]
  deleted: Scalars["Boolean"]["output"]
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>
  icon?: Maybe<Icon>
  id: Scalars["ID"]["output"]
  name: Scalars["String"]["output"]
  updatedAt: Scalars["DateTime"]["output"]
}

export type TagConnection = {
  __typename?: "TagConnection"
  edges: Array<TagEdge>
  pageInfo: PageInfo
}

export type TagEdge = {
  __typename?: "TagEdge"
  cursor: Scalars["String"]["output"]
  node: Tag
}

export type TaskProperties = {
  __typename?: "TaskProperties"
  completed: Scalars["Boolean"]["output"]
  completedAt?: Maybe<Scalars["DateTime"]["output"]>
  completedBy?: Maybe<User>
}

/** A time zone is a region of the globe that observes a uniform standard time. */
export type TimeZone = {
  __typename?: "TimeZone"
  city: Scalars["String"]["output"]
  coordinates: Coordinates
  countryCodes: Array<Scalars["String"]["output"]>
  id: Scalars["ID"]["output"]
  region: Scalars["String"]["output"]
}

export type TimeZoneConnection = {
  __typename?: "TimeZoneConnection"
  edges: Array<TimeZoneEdge>
  pageInfo: PageInfo
}

export type TimeZoneEdge = {
  __typename?: "TimeZoneEdge"
  cursor: Scalars["String"]["output"]
  node: TimeZone
}

export type TokenSetResponse = {
  __typename?: "TokenSetResponse"
  accessToken: Scalars["String"]["output"]
  idToken: Scalars["String"]["output"]
  refreshToken: Scalars["String"]["output"]
}

export type UpdateAccountInput = {
  patch: UpdateAccountPatchInput
}

export type UpdateAccountPatchInput = {
  avatar?: InputMaybe<AvatarInput>
  clientData?: InputMaybe<Array<JsonKeyValuePairInput>>
  countryCode?: InputMaybe<CountryCode>
  defaultAllDayEventAlerts?: InputMaybe<Array<AllDayEventAlertInput>>
  defaultEventAlerts?: InputMaybe<Array<EventAlertInput>>
  locale?: InputMaybe<Scalars["String"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  onboardingCompleted?: InputMaybe<Scalars["Boolean"]["input"]>
  optInToUpdates?: InputMaybe<Scalars["Boolean"]["input"]>
  profileColor?: InputMaybe<ColorInput>
  timeZone?: InputMaybe<Scalars["String"]["input"]>
  username?: InputMaybe<Scalars["String"]["input"]>
  weekStart?: InputMaybe<DayOfWeek>
}

export type UpdateAreaInput = {
  id: Scalars["ID"]["input"]
  patch: UpdateAreaPatchInput
}

export type UpdateAreaPatchInput = {
  color?: InputMaybe<ColorInput>
  icon?: InputMaybe<IconInput>
  name?: InputMaybe<Scalars["String"]["input"]>
}

export type UpdateCalendarInput = {
  id: Scalars["ID"]["input"]
  patch: UpdateCalendarPatchInput
}

export type UpdateCalendarPatchInput = {
  color?: InputMaybe<ColorInput>
  customName?: InputMaybe<Scalars["String"]["input"]>
  defaultAllDayEventAlerts?: InputMaybe<Array<AllDayEventAlertInput>>
  defaultArea?: InputMaybe<Scalars["ID"]["input"]>
  defaultEventAlerts?: InputMaybe<Array<EventAlertInput>>
  defaultTags?: InputMaybe<Array<Scalars["ID"]["input"]>>
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  participants?: InputMaybe<Array<CalendarParticipantPatchInput>>
  timeZone?: InputMaybe<Scalars["String"]["input"]>
}

export type UpdateContactInput = {
  id: Scalars["ID"]["input"]
  patch: UpdateContactPatchInput
}

export type UpdateContactPatchInput = {
  customName?: InputMaybe<Scalars["String"]["input"]>
  favourite?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type UpdateEmailAddressInput = {
  id: Scalars["ID"]["input"]
  patch?: InputMaybe<UpdateEmailAddressPatchInput>
}

export type UpdateEmailAddressPatchInput = {
  contactDiscovery?: InputMaybe<ContactDiscovery>
  primary?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type UpdateItemInput = {
  applyTo?: InputMaybe<ItemApplyTo>
  id: Scalars["ID"]["input"]
  patch: UpdateItemPatchInput
}

export type UpdateItemPatchInput = {
  allDayEventAlerts?: InputMaybe<Array<AllDayEventAlertInput>>
  area?: InputMaybe<Scalars["ID"]["input"]>
  calendar?: InputMaybe<Scalars["ID"]["input"]>
  call?: InputMaybe<CallInput>
  color?: InputMaybe<ColorInput>
  completed?: InputMaybe<Scalars["Boolean"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  end?: InputMaybe<DateTimeInfoInput>
  eventAlerts?: InputMaybe<Array<EventAlertInput>>
  icon?: InputMaybe<IconInput>
  location?: InputMaybe<ItemLocationInput>
  participants?: InputMaybe<Array<ItemParticipantPatchInput>>
  participantsCanEdit?: InputMaybe<Scalars["Boolean"]["input"]>
  participantsCanInvite?: InputMaybe<Scalars["Boolean"]["input"]>
  public?: InputMaybe<PublicEventPatchInput>
  recurrence?: InputMaybe<RecurrenceInput>
  start?: InputMaybe<DateTimeInfoInput>
  tags?: InputMaybe<Array<Scalars["ID"]["input"]>>
  title?: InputMaybe<Scalars["String"]["input"]>
  type?: InputMaybe<ItemType>
}

export type UpdateLocationInput = {
  id: Scalars["ID"]["input"]
  patch: UpdateLocationPatchInput
}

export type UpdateLocationPatchInput = {
  customName?: InputMaybe<Scalars["String"]["input"]>
  icon?: InputMaybe<IconInput>
}

export type UpdateOAuthConnectionInput = {
  id: Scalars["ID"]["input"]
  patch?: InputMaybe<UpdateOAuthConnectionPatchInput>
}

export type UpdateOAuthConnectionPatchInput = {
  customName?: InputMaybe<Scalars["String"]["input"]>
  defaultArea?: InputMaybe<Scalars["ID"]["input"]>
  defaultTags?: InputMaybe<Array<Scalars["ID"]["input"]>>
}

export type UpdatePhoneNumberInput = {
  id: Scalars["ID"]["input"]
  patch?: InputMaybe<UpdatePhoneNumberPatchInput>
}

export type UpdatePhoneNumberPatchInput = {
  contactDiscovery?: InputMaybe<ContactDiscovery>
}

export type UpdateTagInput = {
  id: Scalars["ID"]["input"]
  patch: UpdateTagPatchInput
}

export type UpdateTagPatchInput = {
  color?: InputMaybe<ColorInput>
  icon?: InputMaybe<IconInput>
  name?: InputMaybe<Scalars["String"]["input"]>
}

export type UploadFileInput = {
  idempotencyKey: Scalars["String"]["input"]
  mediaType: Scalars["String"]["input"]
  size: Scalars["Int"]["input"]
  uploadType: UploadType
}

/** Whether or not the upload has been completed */
export enum UploadStatus {
  Completed = "completed",
  Pending = "pending",
}

/** The type of upload to generate a URL for */
export enum UploadType {
  Avatar = "avatar",
  Feedback = "feedback",
}

/** Daybridge user profile. */
export type User = {
  __typename?: "User"
  avatar?: Maybe<Avatar>
  badges: Array<UserBadge>
  country?: Maybe<Country>
  id: Scalars["ID"]["output"]
  joinedAt: Scalars["DateTime"]["output"]
  name?: Maybe<Scalars["String"]["output"]>
  profileColor: Color
  timeZone?: Maybe<TimeZone>
  userNumber?: Maybe<Scalars["Int"]["output"]>
  username?: Maybe<Scalars["String"]["output"]>
}

export type UserBadge = {
  __typename?: "UserBadge"
  color: Color
  icon?: Maybe<Icon>
  id: Scalars["ID"]["output"]
  name: Scalars["String"]["output"]
}

export type UserConnection = {
  __typename?: "UserConnection"
  edges: Array<UserEdge>
  pageInfo: PageInfo
}

export type UserEdge = {
  __typename?: "UserEdge"
  cursor: Scalars["String"]["output"]
  node: User
}

export type VerifyEmailAddressInput = {
  id: Scalars["ID"]["input"]
  verificationCode: Scalars["String"]["input"]
}

export type VerifyPhoneNumberInput = {
  id: Scalars["ID"]["input"]
  verificationCode: Scalars["String"]["input"]
}

export type Viewport = {
  __typename?: "Viewport"
  high: Coordinates
  low: Coordinates
}
