import { useCallback } from "react"
import { RequestInit } from "graphql-request/dist/types.dom"
import { useSession } from "next-auth/react"
import { Session } from "next-auth"
import { Variables } from "graphql-request"
import { useLocale } from "next-intl"
import { tokenExpired } from "../tokenExpired"
import { useGraphQLClient } from "./provider"
import { useAllowUnauthenticatedRequests } from "./unauthenticated"
import { GraphQLError, RawGraphQLErrorRequestResponse } from "./errors"

interface SessionWithTokens extends Session {
  accessToken: string
  refreshToken: string
}

let pendingRefreshPromise: Promise<Session | null> | null = null

/**
 * `useFetcher` is a hook used to access a GraphQL client that can be used to
 * make GraphQL requests to a server.
 *
 * ℹ️ Errors
 * ---------
 * The `graphqlClient` throws errors automatically by parsing the response
 * and checking for the presence of a non-200 status code, an `errors` object, or the
 * absence of a `data` field in the response.
 * See: https://github.com/prisma-labs/graphql-request/blob/c544c5f72942d23668aad6819257521c804f035f/src/index.ts#L65
 *
 * @param query A query document from graphql-query.
 * @param variables An optional set of variables to perform inline replacement
 */
const useFetcher = <TQueryFnData, TVariables extends Variables>(
  query: string,
  headers?: RequestInit["headers"],
): ((variables?: TVariables) => Promise<TQueryFnData>) => {
  const { allowUnauthenticatedRequests } = useAllowUnauthenticatedRequests()
  const session = useSession({ required: !allowUnauthenticatedRequests })
  const locale = useLocale()

  if (!session) {
    throw new Error(
      "useFetcher needs to be used within a SessionProvider, so it can authenticate requests with a user access token. " +
        "Wrap your app in an <SessionProvider> to resolve this issue.",
    )
  }

  const client = useGraphQLClient()
  if (!client) {
    throw new Error(
      "useFetcher needs to be used within a GraphQLProvider, so it knows where to send requests. " +
        "Wrap your app in a <GraphQLProvider> to resolve this issue.",
    )
  }

  return useCallback(
    async (variables?: TVariables): Promise<TQueryFnData> => {
      if (
        !allowUnauthenticatedRequests &&
        (session.status === "unauthenticated" ||
          !(session?.data as SessionWithTokens).accessToken)
      ) {
        throw new Error("User was not authenticated")
      }

      let sessionData, token
      const tokExp = tokenExpired(
        (session.data as SessionWithTokens).accessToken,
      )
      let hadToWaitForRefresh = false
      if (session.status !== "unauthenticated") {
        if (tokExp) {
          if (pendingRefreshPromise) {
            console.debug(
              "🔑 Token refresh already in progress, waiting for it to finish",
            )
            hadToWaitForRefresh = true
            sessionData = (await pendingRefreshPromise) as SessionWithTokens
            token = sessionData?.accessToken
            console.debug("🔑 Got refreshed token")
          } else {
            console.debug("🔑 Token expired, refreshing")
            pendingRefreshPromise = session.update()
            sessionData = (await pendingRefreshPromise) as SessionWithTokens
            token = sessionData?.accessToken
            // eslint-disable-next-line require-atomic-updates
            pendingRefreshPromise = null
            console.debug("🔑 Token refreshed")
          }
        } else {
          sessionData = session?.data as SessionWithTokens
          token = sessionData?.accessToken
        }
      }

      // Debug logging
      if (!token && !allowUnauthenticatedRequests) {
        console.debug(
          "🔑 No token found, but unauthenticated requests are not allowed",
        )
        console.table({
          tokenExpired: tokExp,
          hadToWaitForRefresh,
          originalSessionStatus: session?.status,
          originalSessionData: session?.data,
          newSessionStatus: session?.status,
          newSessionData: sessionData,
        })
      }

      const allHeaders = {
        "Accept-Language": locale,
        ...(token
          ? {
              Authorization: `Bearer ${
                process.env.NODE_ENV === "production"
                  ? token
                  : process.env.NEXT_PUBLIC_LOCAL_AUTH_TOKEN || token
              }`,
            }
          : {}),
        ...(headers ?? {}),
      }

      return client
        .request<TQueryFnData, TVariables>(query, variables, allHeaders)
        .catch((error: RawGraphQLErrorRequestResponse) => {
          // Only throw the first error
          throw new GraphQLError(error.response?.errors?.[0] ?? error)
        })
    },
    [client, query, session, headers, allowUnauthenticatedRequests, locale],
  )
}

export default useFetcher
