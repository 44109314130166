// noinspection JSUnusedGlobalSymbols,SpellCheckingInspection'
// ⛔️ DO NOT EDIT THIS FILE. It's generated from `yarn generate`.
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from "@tanstack/react-query"
import useFetcher from "../../lib/graphql/fetcher"
import { GraphQLError } from "../../lib/graphql/errors"

import * as Types from "./operations"

export const CoordinatesFragmentDoc = `
    fragment Coordinates on Coordinates {
  latitude
  longitude
}
    `
export const TimeZoneFragmentDoc = `
    fragment TimeZone on TimeZone {
  id
  coordinates {
    ...Coordinates
  }
  countryCodes
  region
  city
}
    `
export const CountryFragmentDoc = `
    fragment Country on Country {
  id
  name
  code
  flagEmoji
  coordinates {
    ...Coordinates
  }
  dialCode
  areaCodes
  exampleSubscriberNumberFormat
  exampleSubscriberNumberPlain
}
    `
export const ColorFragmentDoc = `
    fragment Color on Color {
  hue
}
    `
export const IconFragmentDoc = `
    fragment Icon on Icon {
  id
  name
  queryStrings
}
    `
export const BadgeFragmentDoc = `
    fragment Badge on UserBadge {
  id
  name
  icon {
    ...Icon
  }
  color {
    ...Color
  }
}
    `
export const PhoneNumberFragmentDoc = `
    fragment PhoneNumber on PhoneNumber {
  id
  phoneNumber
  countryCode
  contactDiscovery
  verified
  verifiedAt
}
    `
export const EmailAddressFragmentDoc = `
    fragment EmailAddress on EmailAddress {
  id
  emailAddress
  contactDiscovery
  verified
  verifiedAt
  primary
}
    `
export const CurrentAccountFragmentDoc = `
    fragment CurrentAccount on Account {
  id
  name
  avatar {
    url
  }
  username
  userNumber
  timeZone {
    ...TimeZone
  }
  defaultAllDayEventAlerts {
    at
    days
  }
  defaultEventAlerts {
    minutes
  }
  weekStart
  clientData {
    key
    value
  }
  country {
    ...Country
  }
  locale
  profileColor {
    ...Color
  }
  badges {
    ...Badge
  }
  phoneNumbers {
    edges {
      node {
        ...PhoneNumber
      }
    }
  }
  emailAddresses {
    edges {
      node {
        ...EmailAddress
      }
    }
  }
  defaultEventAlerts {
    minutes
  }
  defaultAllDayEventAlerts {
    days
    at
  }
  joinedAt
  onboardingCompleted
  onboardingCompletedAt
  migrated
  optInToUpdates
}
    `
export const UserFragmentDoc = `
    fragment User on User {
  id
  name
  username
  userNumber
  profileColor {
    ...Color
  }
  avatar {
    url
  }
  joinedAt
  timeZone {
    ...TimeZone
  }
  badges {
    ...Badge
  }
  country {
    ...Country
  }
}
    `
export const CalendarParticipantFragmentDoc = `
    fragment CalendarParticipant on CalendarParticipant {
  id
  user {
    ...User
  }
  emailAddress
  role
  status
  invitedAt
  invitedBy {
    ...User
  }
  statusUpdatedAt
}
    `
export const PageInfoFragmentDoc = `
    fragment PageInfo on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `
export const TagFragmentDoc = `
    fragment Tag on Tag {
  id
  color {
    ...Color
  }
  icon {
    ...Icon
  }
  name
  deleted
  deletedAt
  updatedAt
  createdAt
}
    `
export const AreaFragmentDoc = `
    fragment Area on Area {
  id
  name
  color {
    ...Color
  }
  icon {
    ...Icon
  }
  deleted
  deletedAt
  createdAt
  updatedAt
}
    `
export const CalendarFragmentDoc = `
    fragment Calendar on Calendar {
  __typename
  id
  name
  customName
  timeZone {
    ...TimeZone
  }
  defaultAllDayEventAlerts {
    at
    days
  }
  defaultEventAlerts {
    minutes
  }
  participants {
    edges {
      node {
        ...CalendarParticipant
      }
      cursor
    }
    pageInfo {
      ...PageInfo
    }
  }
  defaultTags {
    edges {
      node {
        ...Tag
      }
      cursor
    }
    pageInfo {
      ...PageInfo
    }
  }
  defaultArea {
    ...Area
  }
  ... on GoogleCalendar {
    enabled
  }
  account {
    ... on Account {
      __typename
      id
      providerId
    }
    ... on GoogleOAuthConnection {
      __typename
      id
      providerId
      defaultArea {
        ...Area
      }
      defaultTags {
        edges {
          node {
            ...Tag
          }
          cursor
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  updatedAt
  createdAt
  deleted
}
    `
export const CalendarAccountFragmentDoc = `
    fragment CalendarAccount on CalendarAccount {
  ... on Account {
    __typename
    id
    providerId
    providerName
    username
    calendars {
      edges {
        cursor
        node {
          ...Calendar
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ... on GoogleOAuthConnection {
    __typename
    id
    providerId
    providerName
    accountId
    calendars {
      edges {
        cursor
        node {
          ...Calendar
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
    defaultArea {
      ...Area
    }
    customName
    email
    scopes
    status
    deleted
    deletedAt
    createdAt
    updatedAt
  }
}
    `
export const ContactFragmentDoc = `
    fragment Contact on Contact {
  id
  mutual
  user {
    ...User
  }
  emailAddress
  favourite
  recent
  timesInvited
  lastInvitedAt
  deleted
  deletedAt
  createdAt
  updatedAt
}
    `
export const FeedbackSubmissionFragmentDoc = `
    fragment FeedbackSubmission on SendFeedbackResponse {
  id
  feedbackType
  message
  metadata
  createdAt
  updatedAt
}
    `
export const FileUploadFragmentDoc = `
    fragment FileUpload on FileUpload {
  id
  idempotencyKey
  uploadType
  uploadUrl
  fileUrl
  mediaType
  size
  status
  uploadUrlExpiresAt
  uploadedBy {
    ...User
  }
  updatedAt
  createdAt
}
    `
export const IconGroupFragmentDoc = `
    fragment IconGroup on IconGroup {
  id
  name
  icon {
    ...Icon
  }
  icons {
    edges {
      cursor
      node {
        ...Icon
      }
    }
  }
}
    `
export const DateTimeInfoFragmentDoc = `
    fragment DateTimeInfo on DateTimeInfo {
  date
  dateTime
  timeZone {
    ...TimeZone
  }
}
    `
export const PlaceFragmentDoc = `
    fragment Place on Place {
  id
  displayName
  formattedAddress
  googleMapsUri
  secondaryText
  coordinates {
    ...Coordinates
  }
  shortFormattedAddress
  updatedAt
  viewport {
    high {
      ...Coordinates
    }
    low {
      ...Coordinates
    }
  }
}
    `
export const LocationFragmentDoc = `
    fragment Location on Location {
  id
  customName
  icon {
    ...Icon
  }
  place {
    ...Place
  }
  suggested
}
    `
export const PublicFragmentDoc = `
    fragment Public on PublicEvent {
  id
  color {
    hue
  }
}
    `
export const ParticipantFragmentDoc = `
    fragment Participant on ItemParticipant {
  id
  user {
    ...User
  }
  emailAddress
  canEdit
  canInvite
  invitedAt
  invitedBy {
    ...User
  }
  message
  optional
  respondedAt
  status
}
    `
export const CallFragmentDoc = `
    fragment Call on Call {
  url
}
    `
export const RecurrenceRuleFragmentDoc = `
    fragment RecurrenceRule on RecurrenceRule {
  interval
  count
  frequency
  until
  days {
    day
    n
  }
}
    `
export const ItemFragmentDoc = `
    fragment Item on Item {
  __typename
  debug {
    key
    value
  }
  title
  type
  id
  description
  start {
    ...DateTimeInfo
  }
  end {
    ...DateTimeInfo
  }
  canceled
  deleted
  location {
    location {
      ...Location
    }
    place {
      ...Place
    }
    text
  }
  icon {
    ...Icon
  }
  calendar {
    ...Calendar
  }
  public {
    ...Public
  }
  participants {
    edges {
      cursor
      node {
        ...Participant
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
  participantsCanEdit
  participantsCanInvite
  createdBy {
    ...User
  }
  updatedBy {
    ...User
  }
  createdAt
  updatedAt
  call {
    ...Call
  }
  area {
    ...Area
  }
  tags {
    edges {
      cursor
      node {
        ...Tag
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
  parent {
    id
    start {
      ...DateTimeInfo
    }
    end {
      ...DateTimeInfo
    }
    canceled
    recurrence {
      rules {
        ...RecurrenceRule
      }
    }
  }
}
    `
export const NotificationFragmentDoc = `
    fragment Notification on Notification {
  id
  title
  body
  read
  icon {
    ...Icon
  }
  color {
    ...Color
  }
  imageStyle
  imageUrl
  createdAt
}
    `
export const OAuthConnectionFragmentDoc = `
    fragment OAuthConnection on OAuthConnection {
  __typename
  id
  accountId
  providerId
  providerName
  customName
  email
  scopes
  status
}
    `
export const GoogleOAuthConnectionFragmentDoc = `
    fragment GoogleOAuthConnection on GoogleOAuthConnection {
  defaultArea {
    ...Area
  }
  calendars {
    edges {
      cursor
      node {
        ...Calendar
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    `
export const BootstrapDocument = `
    query Bootstrap {
  currentAccount {
    ...CurrentAccount
    clientData {
      key
      value
    }
    oAuthConnections {
      edges {
        cursor
        node {
          ...OAuthConnection
          ... on GoogleOAuthConnection {
            ...GoogleOAuthConnection
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  areas {
    edges {
      node {
        ...Area
      }
      cursor
    }
    pageInfo {
      ...PageInfo
    }
  }
  calendarAccounts {
    edges {
      __typename
      cursor
      node {
        ...CalendarAccount
      }
    }
  }
  contacts {
    edges {
      cursor
      node {
        ...Contact
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
  countries {
    edges {
      cursor
      node {
        ...Country
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
  iconGroups {
    edges {
      cursor
      node {
        ...IconGroup
      }
    }
  }
  tags {
    edges {
      node {
        ...Tag
      }
      cursor
    }
    pageInfo {
      ...PageInfo
    }
  }
  timeZones {
    edges {
      cursor
      node {
        ...TimeZone
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${CurrentAccountFragmentDoc}
${TimeZoneFragmentDoc}
${CoordinatesFragmentDoc}
${CountryFragmentDoc}
${ColorFragmentDoc}
${BadgeFragmentDoc}
${IconFragmentDoc}
${PhoneNumberFragmentDoc}
${EmailAddressFragmentDoc}
${OAuthConnectionFragmentDoc}
${GoogleOAuthConnectionFragmentDoc}
${AreaFragmentDoc}
${CalendarFragmentDoc}
${CalendarParticipantFragmentDoc}
${UserFragmentDoc}
${PageInfoFragmentDoc}
${TagFragmentDoc}
${CalendarAccountFragmentDoc}
${ContactFragmentDoc}
${IconGroupFragmentDoc}`

export const useBootstrapQuery = <
  TData = Types.BootstrapQuery,
  TError = GraphQLError,
>(
  variables?: Types.BootstrapQueryVariables,
  options?: UseQueryOptions<Types.BootstrapQuery, TError, TData>,
) => {
  return useQuery<Types.BootstrapQuery, TError, TData>(
    variables === undefined ? ["Bootstrap"] : ["Bootstrap", variables],
    useFetcher<Types.BootstrapQuery, Types.BootstrapQueryVariables>(
      BootstrapDocument,
    ).bind(null, variables),
    options,
  )
}

useBootstrapQuery.document = BootstrapDocument

useBootstrapQuery.getKey = (variables?: Types.BootstrapQueryVariables) =>
  variables === undefined ? ["Bootstrap"] : ["Bootstrap", variables]

export const AccountDocument = `
    query Account {
  currentAccount {
    ...CurrentAccount
  }
}
    ${CurrentAccountFragmentDoc}
${TimeZoneFragmentDoc}
${CoordinatesFragmentDoc}
${CountryFragmentDoc}
${ColorFragmentDoc}
${BadgeFragmentDoc}
${IconFragmentDoc}
${PhoneNumberFragmentDoc}
${EmailAddressFragmentDoc}`

export const useAccountQuery = <
  TData = Types.AccountQuery,
  TError = GraphQLError,
>(
  variables?: Types.AccountQueryVariables,
  options?: UseQueryOptions<Types.AccountQuery, TError, TData>,
) => {
  return useQuery<Types.AccountQuery, TError, TData>(
    variables === undefined ? ["Account"] : ["Account", variables],
    useFetcher<Types.AccountQuery, Types.AccountQueryVariables>(
      AccountDocument,
    ).bind(null, variables),
    options,
  )
}

useAccountQuery.document = AccountDocument

useAccountQuery.getKey = (variables?: Types.AccountQueryVariables) =>
  variables === undefined ? ["Account"] : ["Account", variables]

export const UpdateAccountDocument = `
    mutation UpdateAccount($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    id
  }
}
    `

export const useUpdateAccountMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.UpdateAccountMutation,
    TError,
    Types.UpdateAccountMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.UpdateAccountMutation,
    TError,
    Types.UpdateAccountMutationVariables,
    TContext
  >(
    ["UpdateAccount"],
    useFetcher<
      Types.UpdateAccountMutation,
      Types.UpdateAccountMutationVariables
    >(UpdateAccountDocument),
    options,
  )
}

export const CreatePhoneDocument = `
    mutation CreatePhone($input: CreatePhoneNumberInput!) {
  createPhoneNumber(input: $input) {
    id
  }
}
    `

export const useCreatePhoneMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.CreatePhoneMutation,
    TError,
    Types.CreatePhoneMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.CreatePhoneMutation,
    TError,
    Types.CreatePhoneMutationVariables,
    TContext
  >(
    ["CreatePhone"],
    useFetcher<Types.CreatePhoneMutation, Types.CreatePhoneMutationVariables>(
      CreatePhoneDocument,
    ),
    options,
  )
}

export const UpdatePhoneDocument = `
    mutation UpdatePhone($input: UpdatePhoneNumberInput!) {
  updatePhoneNumber(input: $input) {
    id
  }
}
    `

export const useUpdatePhoneMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.UpdatePhoneMutation,
    TError,
    Types.UpdatePhoneMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.UpdatePhoneMutation,
    TError,
    Types.UpdatePhoneMutationVariables,
    TContext
  >(
    ["UpdatePhone"],
    useFetcher<Types.UpdatePhoneMutation, Types.UpdatePhoneMutationVariables>(
      UpdatePhoneDocument,
    ),
    options,
  )
}

export const DeletePhoneDocument = `
    mutation DeletePhone($input: DeletePhoneNumberInput!) {
  deletePhoneNumber(input: $input) {
    id
  }
}
    `

export const useDeletePhoneMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.DeletePhoneMutation,
    TError,
    Types.DeletePhoneMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.DeletePhoneMutation,
    TError,
    Types.DeletePhoneMutationVariables,
    TContext
  >(
    ["DeletePhone"],
    useFetcher<Types.DeletePhoneMutation, Types.DeletePhoneMutationVariables>(
      DeletePhoneDocument,
    ),
    options,
  )
}

export const VerifyPhoneDocument = `
    mutation VerifyPhone($input: VerifyPhoneNumberInput!) {
  verifyPhoneNumber(input: $input) {
    id
  }
}
    `

export const useVerifyPhoneMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.VerifyPhoneMutation,
    TError,
    Types.VerifyPhoneMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.VerifyPhoneMutation,
    TError,
    Types.VerifyPhoneMutationVariables,
    TContext
  >(
    ["VerifyPhone"],
    useFetcher<Types.VerifyPhoneMutation, Types.VerifyPhoneMutationVariables>(
      VerifyPhoneDocument,
    ),
    options,
  )
}

export const ResendPhoneVerificationDocument = `
    mutation ResendPhoneVerification($input: ResendPhoneNumberVerificationInput!) {
  resendPhoneNumberVerification(input: $input) {
    id
  }
}
    `

export const useResendPhoneVerificationMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.ResendPhoneVerificationMutation,
    TError,
    Types.ResendPhoneVerificationMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.ResendPhoneVerificationMutation,
    TError,
    Types.ResendPhoneVerificationMutationVariables,
    TContext
  >(
    ["ResendPhoneVerification"],
    useFetcher<
      Types.ResendPhoneVerificationMutation,
      Types.ResendPhoneVerificationMutationVariables
    >(ResendPhoneVerificationDocument),
    options,
  )
}

export const CreateEmailDocument = `
    mutation CreateEmail($input: CreateEmailAddressInput!) {
  createEmailAddress(input: $input) {
    id
  }
}
    `

export const useCreateEmailMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.CreateEmailMutation,
    TError,
    Types.CreateEmailMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.CreateEmailMutation,
    TError,
    Types.CreateEmailMutationVariables,
    TContext
  >(
    ["CreateEmail"],
    useFetcher<Types.CreateEmailMutation, Types.CreateEmailMutationVariables>(
      CreateEmailDocument,
    ),
    options,
  )
}

export const UpdateEmailDocument = `
    mutation UpdateEmail($input: UpdateEmailAddressInput!) {
  updateEmailAddress(input: $input) {
    id
  }
}
    `

export const useUpdateEmailMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.UpdateEmailMutation,
    TError,
    Types.UpdateEmailMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.UpdateEmailMutation,
    TError,
    Types.UpdateEmailMutationVariables,
    TContext
  >(
    ["UpdateEmail"],
    useFetcher<Types.UpdateEmailMutation, Types.UpdateEmailMutationVariables>(
      UpdateEmailDocument,
    ),
    options,
  )
}

export const DeleteEmailDocument = `
    mutation DeleteEmail($input: DeleteEmailAddressInput!) {
  deleteEmailAddress(input: $input) {
    id
  }
}
    `

export const useDeleteEmailMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.DeleteEmailMutation,
    TError,
    Types.DeleteEmailMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.DeleteEmailMutation,
    TError,
    Types.DeleteEmailMutationVariables,
    TContext
  >(
    ["DeleteEmail"],
    useFetcher<Types.DeleteEmailMutation, Types.DeleteEmailMutationVariables>(
      DeleteEmailDocument,
    ),
    options,
  )
}

export const VerifyEmailDocument = `
    mutation VerifyEmail($input: VerifyEmailAddressInput!) {
  verifyEmailAddress(input: $input) {
    id
  }
}
    `

export const useVerifyEmailMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.VerifyEmailMutation,
    TError,
    Types.VerifyEmailMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.VerifyEmailMutation,
    TError,
    Types.VerifyEmailMutationVariables,
    TContext
  >(
    ["VerifyEmail"],
    useFetcher<Types.VerifyEmailMutation, Types.VerifyEmailMutationVariables>(
      VerifyEmailDocument,
    ),
    options,
  )
}

export const ResendEmailVerificationDocument = `
    mutation ResendEmailVerification($input: ResendEmailAddressVerificationInput!) {
  resendEmailAddressVerification(input: $input) {
    id
  }
}
    `

export const useResendEmailVerificationMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.ResendEmailVerificationMutation,
    TError,
    Types.ResendEmailVerificationMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.ResendEmailVerificationMutation,
    TError,
    Types.ResendEmailVerificationMutationVariables,
    TContext
  >(
    ["ResendEmailVerification"],
    useFetcher<
      Types.ResendEmailVerificationMutation,
      Types.ResendEmailVerificationMutationVariables
    >(ResendEmailVerificationDocument),
    options,
  )
}

export const LinkUserDocument = `
    mutation LinkUser($input: LinkUserInput!) {
  linkUser(input: $input) {
    id
  }
}
    `

export const useLinkUserMutation = <TError = GraphQLError, TContext = unknown>(
  options?: UseMutationOptions<
    Types.LinkUserMutation,
    TError,
    Types.LinkUserMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.LinkUserMutation,
    TError,
    Types.LinkUserMutationVariables,
    TContext
  >(
    ["LinkUser"],
    useFetcher<Types.LinkUserMutation, Types.LinkUserMutationVariables>(
      LinkUserDocument,
    ),
    options,
  )
}

export const DeleteAccountDocument = `
    mutation DeleteAccount {
  deleteAccount {
    id
  }
}
    `

export const useDeleteAccountMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.DeleteAccountMutation,
    TError,
    Types.DeleteAccountMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.DeleteAccountMutation,
    TError,
    Types.DeleteAccountMutationVariables,
    TContext
  >(
    ["DeleteAccount"],
    useFetcher<
      Types.DeleteAccountMutation,
      Types.DeleteAccountMutationVariables
    >(DeleteAccountDocument),
    options,
  )
}

export const AreasDocument = `
    query Areas($first: Int, $after: String, $last: Int, $before: String) {
  areas(first: $first, after: $after, last: $last, before: $before) {
    edges {
      node {
        ...Area
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${AreaFragmentDoc}
${ColorFragmentDoc}
${IconFragmentDoc}
${PageInfoFragmentDoc}`

export const useAreasQuery = <TData = Types.AreasQuery, TError = GraphQLError>(
  variables?: Types.AreasQueryVariables,
  options?: UseQueryOptions<Types.AreasQuery, TError, TData>,
) => {
  return useQuery<Types.AreasQuery, TError, TData>(
    variables === undefined ? ["Areas"] : ["Areas", variables],
    useFetcher<Types.AreasQuery, Types.AreasQueryVariables>(AreasDocument).bind(
      null,
      variables,
    ),
    options,
  )
}

useAreasQuery.document = AreasDocument

useAreasQuery.getKey = (variables?: Types.AreasQueryVariables) =>
  variables === undefined ? ["Areas"] : ["Areas", variables]

export const CreateAreaDocument = `
    mutation CreateArea($input: CreateAreaInput!) {
  createArea: createArea(input: $input) {
    ...Area
  }
}
    ${AreaFragmentDoc}
${ColorFragmentDoc}
${IconFragmentDoc}`

export const useCreateAreaMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.CreateAreaMutation,
    TError,
    Types.CreateAreaMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.CreateAreaMutation,
    TError,
    Types.CreateAreaMutationVariables,
    TContext
  >(
    ["CreateArea"],
    useFetcher<Types.CreateAreaMutation, Types.CreateAreaMutationVariables>(
      CreateAreaDocument,
    ),
    options,
  )
}

export const UpdateAreaDocument = `
    mutation UpdateArea($input: UpdateAreaInput!) {
  updateArea(input: $input) {
    ...Area
  }
}
    ${AreaFragmentDoc}
${ColorFragmentDoc}
${IconFragmentDoc}`

export const useUpdateAreaMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.UpdateAreaMutation,
    TError,
    Types.UpdateAreaMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.UpdateAreaMutation,
    TError,
    Types.UpdateAreaMutationVariables,
    TContext
  >(
    ["UpdateArea"],
    useFetcher<Types.UpdateAreaMutation, Types.UpdateAreaMutationVariables>(
      UpdateAreaDocument,
    ),
    options,
  )
}

export const DeleteAreaDocument = `
    mutation DeleteArea($input: DeleteAreaInput!) {
  deleteArea(input: $input) {
    ...Area
  }
}
    ${AreaFragmentDoc}
${ColorFragmentDoc}
${IconFragmentDoc}`

export const useDeleteAreaMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.DeleteAreaMutation,
    TError,
    Types.DeleteAreaMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.DeleteAreaMutation,
    TError,
    Types.DeleteAreaMutationVariables,
    TContext
  >(
    ["DeleteArea"],
    useFetcher<Types.DeleteAreaMutation, Types.DeleteAreaMutationVariables>(
      DeleteAreaDocument,
    ),
    options,
  )
}

export const ReorderAreasDocument = `
    mutation ReorderAreas($input: ReorderAreasInput!) {
  reorderAreas(input: $input)
}
    `

export const useReorderAreasMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.ReorderAreasMutation,
    TError,
    Types.ReorderAreasMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.ReorderAreasMutation,
    TError,
    Types.ReorderAreasMutationVariables,
    TContext
  >(
    ["ReorderAreas"],
    useFetcher<Types.ReorderAreasMutation, Types.ReorderAreasMutationVariables>(
      ReorderAreasDocument,
    ),
    options,
  )
}

export const CalendarAccountsDocument = `
    query CalendarAccounts($first: Int, $after: String, $last: Int, $before: String) {
  calendarAccounts(first: $first, after: $after, last: $last, before: $before) {
    edges {
      __typename
      cursor
      node {
        ...CalendarAccount
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${CalendarAccountFragmentDoc}
${CalendarFragmentDoc}
${TimeZoneFragmentDoc}
${CoordinatesFragmentDoc}
${CalendarParticipantFragmentDoc}
${UserFragmentDoc}
${ColorFragmentDoc}
${BadgeFragmentDoc}
${IconFragmentDoc}
${CountryFragmentDoc}
${PageInfoFragmentDoc}
${TagFragmentDoc}
${AreaFragmentDoc}`

export const useCalendarAccountsQuery = <
  TData = Types.CalendarAccountsQuery,
  TError = GraphQLError,
>(
  variables?: Types.CalendarAccountsQueryVariables,
  options?: UseQueryOptions<Types.CalendarAccountsQuery, TError, TData>,
) => {
  return useQuery<Types.CalendarAccountsQuery, TError, TData>(
    variables === undefined
      ? ["CalendarAccounts"]
      : ["CalendarAccounts", variables],
    useFetcher<
      Types.CalendarAccountsQuery,
      Types.CalendarAccountsQueryVariables
    >(CalendarAccountsDocument).bind(null, variables),
    options,
  )
}

useCalendarAccountsQuery.document = CalendarAccountsDocument

useCalendarAccountsQuery.getKey = (
  variables?: Types.CalendarAccountsQueryVariables,
) =>
  variables === undefined
    ? ["CalendarAccounts"]
    : ["CalendarAccounts", variables]

export const CreateCalendarDocument = `
    mutation CreateCalendar($input: CreateCalendarInput!) {
  createCalendar(input: $input) {
    ...Calendar
  }
}
    ${CalendarFragmentDoc}
${TimeZoneFragmentDoc}
${CoordinatesFragmentDoc}
${CalendarParticipantFragmentDoc}
${UserFragmentDoc}
${ColorFragmentDoc}
${BadgeFragmentDoc}
${IconFragmentDoc}
${CountryFragmentDoc}
${PageInfoFragmentDoc}
${TagFragmentDoc}
${AreaFragmentDoc}`

export const useCreateCalendarMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.CreateCalendarMutation,
    TError,
    Types.CreateCalendarMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.CreateCalendarMutation,
    TError,
    Types.CreateCalendarMutationVariables,
    TContext
  >(
    ["CreateCalendar"],
    useFetcher<
      Types.CreateCalendarMutation,
      Types.CreateCalendarMutationVariables
    >(CreateCalendarDocument),
    options,
  )
}

export const UpdateCalendarDocument = `
    mutation UpdateCalendar($input: UpdateCalendarInput!) {
  updateCalendar(input: $input) {
    ...Calendar
  }
}
    ${CalendarFragmentDoc}
${TimeZoneFragmentDoc}
${CoordinatesFragmentDoc}
${CalendarParticipantFragmentDoc}
${UserFragmentDoc}
${ColorFragmentDoc}
${BadgeFragmentDoc}
${IconFragmentDoc}
${CountryFragmentDoc}
${PageInfoFragmentDoc}
${TagFragmentDoc}
${AreaFragmentDoc}`

export const useUpdateCalendarMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.UpdateCalendarMutation,
    TError,
    Types.UpdateCalendarMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.UpdateCalendarMutation,
    TError,
    Types.UpdateCalendarMutationVariables,
    TContext
  >(
    ["UpdateCalendar"],
    useFetcher<
      Types.UpdateCalendarMutation,
      Types.UpdateCalendarMutationVariables
    >(UpdateCalendarDocument),
    options,
  )
}

export const ReorderCalendarsDocument = `
    mutation ReorderCalendars($sortOrder: [ID!]!, $group: String!) {
  reorderCalendars(input: {sortOrder: $sortOrder, calendarAccount: $group})
}
    `

export const useReorderCalendarsMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.ReorderCalendarsMutation,
    TError,
    Types.ReorderCalendarsMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.ReorderCalendarsMutation,
    TError,
    Types.ReorderCalendarsMutationVariables,
    TContext
  >(
    ["ReorderCalendars"],
    useFetcher<
      Types.ReorderCalendarsMutation,
      Types.ReorderCalendarsMutationVariables
    >(ReorderCalendarsDocument),
    options,
  )
}

export const DeleteCalendarDocument = `
    mutation DeleteCalendar($input: DeleteCalendarInput!) {
  deleteCalendar(input: $input) {
    ...Calendar
  }
}
    ${CalendarFragmentDoc}
${TimeZoneFragmentDoc}
${CoordinatesFragmentDoc}
${CalendarParticipantFragmentDoc}
${UserFragmentDoc}
${ColorFragmentDoc}
${BadgeFragmentDoc}
${IconFragmentDoc}
${CountryFragmentDoc}
${PageInfoFragmentDoc}
${TagFragmentDoc}
${AreaFragmentDoc}`

export const useDeleteCalendarMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.DeleteCalendarMutation,
    TError,
    Types.DeleteCalendarMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.DeleteCalendarMutation,
    TError,
    Types.DeleteCalendarMutationVariables,
    TContext
  >(
    ["DeleteCalendar"],
    useFetcher<
      Types.DeleteCalendarMutation,
      Types.DeleteCalendarMutationVariables
    >(DeleteCalendarDocument),
    options,
  )
}

export const ContactsDocument = `
    query Contacts($first: Int, $after: String, $last: Int, $before: String) {
  contacts(first: $first, after: $after, last: $last, before: $before) {
    edges {
      cursor
      node {
        ...Contact
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${ContactFragmentDoc}
${UserFragmentDoc}
${ColorFragmentDoc}
${TimeZoneFragmentDoc}
${CoordinatesFragmentDoc}
${BadgeFragmentDoc}
${IconFragmentDoc}
${CountryFragmentDoc}
${PageInfoFragmentDoc}`

export const useContactsQuery = <
  TData = Types.ContactsQuery,
  TError = GraphQLError,
>(
  variables?: Types.ContactsQueryVariables,
  options?: UseQueryOptions<Types.ContactsQuery, TError, TData>,
) => {
  return useQuery<Types.ContactsQuery, TError, TData>(
    variables === undefined ? ["Contacts"] : ["Contacts", variables],
    useFetcher<Types.ContactsQuery, Types.ContactsQueryVariables>(
      ContactsDocument,
    ).bind(null, variables),
    options,
  )
}

useContactsQuery.document = ContactsDocument

useContactsQuery.getKey = (variables?: Types.ContactsQueryVariables) =>
  variables === undefined ? ["Contacts"] : ["Contacts", variables]

export const SendFeedbackDocument = `
    mutation SendFeedback($input: SendFeedbackInput!) {
  sendFeedback(input: $input) {
    ...FeedbackSubmission
  }
}
    ${FeedbackSubmissionFragmentDoc}`

export const useSendFeedbackMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.SendFeedbackMutation,
    TError,
    Types.SendFeedbackMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.SendFeedbackMutation,
    TError,
    Types.SendFeedbackMutationVariables,
    TContext
  >(
    ["SendFeedback"],
    useFetcher<Types.SendFeedbackMutation, Types.SendFeedbackMutationVariables>(
      SendFeedbackDocument,
    ),
    options,
  )
}

export const UploadFileDocument = `
    mutation UploadFile($input: UploadFileInput!) {
  uploadFile(input: $input) {
    ...FileUpload
  }
}
    ${FileUploadFragmentDoc}
${UserFragmentDoc}
${ColorFragmentDoc}
${TimeZoneFragmentDoc}
${CoordinatesFragmentDoc}
${BadgeFragmentDoc}
${IconFragmentDoc}
${CountryFragmentDoc}`

export const useUploadFileMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.UploadFileMutation,
    TError,
    Types.UploadFileMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.UploadFileMutation,
    TError,
    Types.UploadFileMutationVariables,
    TContext
  >(
    ["UploadFile"],
    useFetcher<Types.UploadFileMutation, Types.UploadFileMutationVariables>(
      UploadFileDocument,
    ),
    options,
  )
}

export const GeoDataDocument = `
    query GeoData {
  countries {
    edges {
      cursor
      node {
        ...Country
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
  timeZones {
    edges {
      cursor
      node {
        ...TimeZone
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${CountryFragmentDoc}
${CoordinatesFragmentDoc}
${PageInfoFragmentDoc}
${TimeZoneFragmentDoc}`

export const useGeoDataQuery = <
  TData = Types.GeoDataQuery,
  TError = GraphQLError,
>(
  variables?: Types.GeoDataQueryVariables,
  options?: UseQueryOptions<Types.GeoDataQuery, TError, TData>,
) => {
  return useQuery<Types.GeoDataQuery, TError, TData>(
    variables === undefined ? ["GeoData"] : ["GeoData", variables],
    useFetcher<Types.GeoDataQuery, Types.GeoDataQueryVariables>(
      GeoDataDocument,
    ).bind(null, variables),
    options,
  )
}

useGeoDataQuery.document = GeoDataDocument

useGeoDataQuery.getKey = (variables?: Types.GeoDataQueryVariables) =>
  variables === undefined ? ["GeoData"] : ["GeoData", variables]

export const IconsDocument = `
    query Icons {
  iconGroups {
    edges {
      cursor
      node {
        ...IconGroup
      }
    }
  }
}
    ${IconGroupFragmentDoc}
${IconFragmentDoc}`

export const useIconsQuery = <TData = Types.IconsQuery, TError = GraphQLError>(
  variables?: Types.IconsQueryVariables,
  options?: UseQueryOptions<Types.IconsQuery, TError, TData>,
) => {
  return useQuery<Types.IconsQuery, TError, TData>(
    variables === undefined ? ["Icons"] : ["Icons", variables],
    useFetcher<Types.IconsQuery, Types.IconsQueryVariables>(IconsDocument).bind(
      null,
      variables,
    ),
    options,
  )
}

useIconsQuery.document = IconsDocument

useIconsQuery.getKey = (variables?: Types.IconsQueryVariables) =>
  variables === undefined ? ["Icons"] : ["Icons", variables]

export const ItemDocument = `
    query Item($itemId: ID!) {
  item(id: $itemId) {
    ...Item
  }
}
    ${ItemFragmentDoc}
${DateTimeInfoFragmentDoc}
${TimeZoneFragmentDoc}
${CoordinatesFragmentDoc}
${LocationFragmentDoc}
${IconFragmentDoc}
${PlaceFragmentDoc}
${CalendarFragmentDoc}
${CalendarParticipantFragmentDoc}
${UserFragmentDoc}
${ColorFragmentDoc}
${BadgeFragmentDoc}
${CountryFragmentDoc}
${PageInfoFragmentDoc}
${TagFragmentDoc}
${AreaFragmentDoc}
${PublicFragmentDoc}
${ParticipantFragmentDoc}
${CallFragmentDoc}
${RecurrenceRuleFragmentDoc}`

export const useItemQuery = <TData = Types.ItemQuery, TError = GraphQLError>(
  variables: Types.ItemQueryVariables,
  options?: UseQueryOptions<Types.ItemQuery, TError, TData>,
) => {
  return useQuery<Types.ItemQuery, TError, TData>(
    ["Item", variables],
    useFetcher<Types.ItemQuery, Types.ItemQueryVariables>(ItemDocument).bind(
      null,
      variables,
    ),
    options,
  )
}

useItemQuery.document = ItemDocument

useItemQuery.getKey = (variables: Types.ItemQueryVariables) => [
  "Item",
  variables,
]

export const ItemsDocument = `
    query Items($endingAfter: DateTime!, $startingBefore: DateTime!, $first: Int, $after: String, $last: Int, $before: String, $timeZone: String) {
  items(
    endingAfter: $endingAfter
    startingBefore: $startingBefore
    after: $after
    before: $before
    first: $first
    last: $last
    timeZone: $timeZone
  ) {
    edges {
      cursor
      node {
        ...Item
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${ItemFragmentDoc}
${DateTimeInfoFragmentDoc}
${TimeZoneFragmentDoc}
${CoordinatesFragmentDoc}
${LocationFragmentDoc}
${IconFragmentDoc}
${PlaceFragmentDoc}
${CalendarFragmentDoc}
${CalendarParticipantFragmentDoc}
${UserFragmentDoc}
${ColorFragmentDoc}
${BadgeFragmentDoc}
${CountryFragmentDoc}
${PageInfoFragmentDoc}
${TagFragmentDoc}
${AreaFragmentDoc}
${PublicFragmentDoc}
${ParticipantFragmentDoc}
${CallFragmentDoc}
${RecurrenceRuleFragmentDoc}`

export const useItemsQuery = <TData = Types.ItemsQuery, TError = GraphQLError>(
  variables: Types.ItemsQueryVariables,
  options?: UseQueryOptions<Types.ItemsQuery, TError, TData>,
) => {
  return useQuery<Types.ItemsQuery, TError, TData>(
    ["Items", variables],
    useFetcher<Types.ItemsQuery, Types.ItemsQueryVariables>(ItemsDocument).bind(
      null,
      variables,
    ),
    options,
  )
}

useItemsQuery.document = ItemsDocument

useItemsQuery.getKey = (variables: Types.ItemsQueryVariables) => [
  "Items",
  variables,
]

export const CreateItemDocument = `
    mutation CreateItem($input: CreateItemInput!) {
  createItem(input: $input) {
    ...Item
  }
}
    ${ItemFragmentDoc}
${DateTimeInfoFragmentDoc}
${TimeZoneFragmentDoc}
${CoordinatesFragmentDoc}
${LocationFragmentDoc}
${IconFragmentDoc}
${PlaceFragmentDoc}
${CalendarFragmentDoc}
${CalendarParticipantFragmentDoc}
${UserFragmentDoc}
${ColorFragmentDoc}
${BadgeFragmentDoc}
${CountryFragmentDoc}
${PageInfoFragmentDoc}
${TagFragmentDoc}
${AreaFragmentDoc}
${PublicFragmentDoc}
${ParticipantFragmentDoc}
${CallFragmentDoc}
${RecurrenceRuleFragmentDoc}`

export const useCreateItemMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.CreateItemMutation,
    TError,
    Types.CreateItemMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.CreateItemMutation,
    TError,
    Types.CreateItemMutationVariables,
    TContext
  >(
    ["CreateItem"],
    useFetcher<Types.CreateItemMutation, Types.CreateItemMutationVariables>(
      CreateItemDocument,
    ),
    options,
  )
}

export const UpdateItemDocument = `
    mutation UpdateItem($input: UpdateItemInput!) {
  updateItem(input: $input) {
    ...Item
  }
}
    ${ItemFragmentDoc}
${DateTimeInfoFragmentDoc}
${TimeZoneFragmentDoc}
${CoordinatesFragmentDoc}
${LocationFragmentDoc}
${IconFragmentDoc}
${PlaceFragmentDoc}
${CalendarFragmentDoc}
${CalendarParticipantFragmentDoc}
${UserFragmentDoc}
${ColorFragmentDoc}
${BadgeFragmentDoc}
${CountryFragmentDoc}
${PageInfoFragmentDoc}
${TagFragmentDoc}
${AreaFragmentDoc}
${PublicFragmentDoc}
${ParticipantFragmentDoc}
${CallFragmentDoc}
${RecurrenceRuleFragmentDoc}`

export const useUpdateItemMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.UpdateItemMutation,
    TError,
    Types.UpdateItemMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.UpdateItemMutation,
    TError,
    Types.UpdateItemMutationVariables,
    TContext
  >(
    ["UpdateItem"],
    useFetcher<Types.UpdateItemMutation, Types.UpdateItemMutationVariables>(
      UpdateItemDocument,
    ),
    options,
  )
}

export const DeleteItemDocument = `
    mutation DeleteItem($input: DeleteItemInput!) {
  deleteItem(input: $input) {
    ...Item
  }
}
    ${ItemFragmentDoc}
${DateTimeInfoFragmentDoc}
${TimeZoneFragmentDoc}
${CoordinatesFragmentDoc}
${LocationFragmentDoc}
${IconFragmentDoc}
${PlaceFragmentDoc}
${CalendarFragmentDoc}
${CalendarParticipantFragmentDoc}
${UserFragmentDoc}
${ColorFragmentDoc}
${BadgeFragmentDoc}
${CountryFragmentDoc}
${PageInfoFragmentDoc}
${TagFragmentDoc}
${AreaFragmentDoc}
${PublicFragmentDoc}
${ParticipantFragmentDoc}
${CallFragmentDoc}
${RecurrenceRuleFragmentDoc}`

export const useDeleteItemMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.DeleteItemMutation,
    TError,
    Types.DeleteItemMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.DeleteItemMutation,
    TError,
    Types.DeleteItemMutationVariables,
    TContext
  >(
    ["DeleteItem"],
    useFetcher<Types.DeleteItemMutation, Types.DeleteItemMutationVariables>(
      DeleteItemDocument,
    ),
    options,
  )
}

export const SetLocationBiasDocument = `
    mutation SetLocationBias($latitude: Float!, $longitude: Float!) {
  setCurrentLocationBias(input: {latitude: $latitude, longitude: $longitude}) {
    latitude
    longitude
  }
}
    `

export const useSetLocationBiasMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.SetLocationBiasMutation,
    TError,
    Types.SetLocationBiasMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.SetLocationBiasMutation,
    TError,
    Types.SetLocationBiasMutationVariables,
    TContext
  >(
    ["SetLocationBias"],
    useFetcher<
      Types.SetLocationBiasMutation,
      Types.SetLocationBiasMutationVariables
    >(SetLocationBiasDocument),
    options,
  )
}

export const LocationsDocument = `
    query Locations($first: Int, $after: String, $last: Int, $before: String) {
  locations(first: $first, after: $after, last: $last, before: $before) {
    edges {
      node {
        ...Location
      }
      cursor
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${LocationFragmentDoc}
${IconFragmentDoc}
${PlaceFragmentDoc}
${CoordinatesFragmentDoc}
${PageInfoFragmentDoc}`

export const useLocationsQuery = <
  TData = Types.LocationsQuery,
  TError = GraphQLError,
>(
  variables?: Types.LocationsQueryVariables,
  options?: UseQueryOptions<Types.LocationsQuery, TError, TData>,
) => {
  return useQuery<Types.LocationsQuery, TError, TData>(
    variables === undefined ? ["Locations"] : ["Locations", variables],
    useFetcher<Types.LocationsQuery, Types.LocationsQueryVariables>(
      LocationsDocument,
    ).bind(null, variables),
    options,
  )
}

useLocationsQuery.document = LocationsDocument

useLocationsQuery.getKey = (variables?: Types.LocationsQueryVariables) =>
  variables === undefined ? ["Locations"] : ["Locations", variables]

export const SearchLocationsDocument = `
    query SearchLocations($query: String!, $sessionToken: String!) {
  searchLocations(query: $query, sessionToken: $sessionToken) {
    edges {
      node {
        ... on Location {
          __typename
          ...Location
        }
        ... on Place {
          __typename
          ...Place
        }
      }
      cursor
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${LocationFragmentDoc}
${IconFragmentDoc}
${PlaceFragmentDoc}
${CoordinatesFragmentDoc}
${PageInfoFragmentDoc}`

export const useSearchLocationsQuery = <
  TData = Types.SearchLocationsQuery,
  TError = GraphQLError,
>(
  variables: Types.SearchLocationsQueryVariables,
  options?: UseQueryOptions<Types.SearchLocationsQuery, TError, TData>,
) => {
  return useQuery<Types.SearchLocationsQuery, TError, TData>(
    ["SearchLocations", variables],
    useFetcher<Types.SearchLocationsQuery, Types.SearchLocationsQueryVariables>(
      SearchLocationsDocument,
    ).bind(null, variables),
    options,
  )
}

useSearchLocationsQuery.document = SearchLocationsDocument

useSearchLocationsQuery.getKey = (
  variables: Types.SearchLocationsQueryVariables,
) => ["SearchLocations", variables]

export const PlaceDocument = `
    query Place($placeId: ID!, $sessionToken: String!) {
  place(id: $placeId, sessionToken: $sessionToken) {
    ...Place
  }
}
    ${PlaceFragmentDoc}
${CoordinatesFragmentDoc}`

export const usePlaceQuery = <TData = Types.PlaceQuery, TError = GraphQLError>(
  variables: Types.PlaceQueryVariables,
  options?: UseQueryOptions<Types.PlaceQuery, TError, TData>,
) => {
  return useQuery<Types.PlaceQuery, TError, TData>(
    ["Place", variables],
    useFetcher<Types.PlaceQuery, Types.PlaceQueryVariables>(PlaceDocument).bind(
      null,
      variables,
    ),
    options,
  )
}

usePlaceQuery.document = PlaceDocument

usePlaceQuery.getKey = (variables: Types.PlaceQueryVariables) => [
  "Place",
  variables,
]

export const CreateLocationDocument = `
    mutation CreateLocation($input: CreateLocationInput!) {
  createLocation(input: $input) {
    ...Location
  }
}
    ${LocationFragmentDoc}
${IconFragmentDoc}
${PlaceFragmentDoc}
${CoordinatesFragmentDoc}`

export const useCreateLocationMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.CreateLocationMutation,
    TError,
    Types.CreateLocationMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.CreateLocationMutation,
    TError,
    Types.CreateLocationMutationVariables,
    TContext
  >(
    ["CreateLocation"],
    useFetcher<
      Types.CreateLocationMutation,
      Types.CreateLocationMutationVariables
    >(CreateLocationDocument),
    options,
  )
}

export const UpdateLocationDocument = `
    mutation UpdateLocation($input: UpdateLocationInput!) {
  updateLocation(input: $input) {
    id
  }
}
    `

export const useUpdateLocationMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.UpdateLocationMutation,
    TError,
    Types.UpdateLocationMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.UpdateLocationMutation,
    TError,
    Types.UpdateLocationMutationVariables,
    TContext
  >(
    ["UpdateLocation"],
    useFetcher<
      Types.UpdateLocationMutation,
      Types.UpdateLocationMutationVariables
    >(UpdateLocationDocument),
    options,
  )
}

export const DeleteLocationDocument = `
    mutation DeleteLocation($input: DeleteLocationInput!) {
  deleteLocation(input: $input) {
    id
  }
}
    `

export const useDeleteLocationMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.DeleteLocationMutation,
    TError,
    Types.DeleteLocationMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.DeleteLocationMutation,
    TError,
    Types.DeleteLocationMutationVariables,
    TContext
  >(
    ["DeleteLocation"],
    useFetcher<
      Types.DeleteLocationMutation,
      Types.DeleteLocationMutationVariables
    >(DeleteLocationDocument),
    options,
  )
}

export const ReorderLocationsDocument = `
    mutation ReorderLocations($input: ReorderLocationsInput!) {
  reorderLocations(input: $input)
}
    `

export const useReorderLocationsMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.ReorderLocationsMutation,
    TError,
    Types.ReorderLocationsMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.ReorderLocationsMutation,
    TError,
    Types.ReorderLocationsMutationVariables,
    TContext
  >(
    ["ReorderLocations"],
    useFetcher<
      Types.ReorderLocationsMutation,
      Types.ReorderLocationsMutationVariables
    >(ReorderLocationsDocument),
    options,
  )
}

export const NotificationsDocument = `
    query Notifications($first: Int, $after: String, $last: Int, $before: String) {
  notifications(first: $first, after: $after, last: $last, before: $before) {
    edges {
      cursor
      node {
        ...Notification
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${NotificationFragmentDoc}
${IconFragmentDoc}
${ColorFragmentDoc}
${PageInfoFragmentDoc}`

export const useNotificationsQuery = <
  TData = Types.NotificationsQuery,
  TError = GraphQLError,
>(
  variables?: Types.NotificationsQueryVariables,
  options?: UseQueryOptions<Types.NotificationsQuery, TError, TData>,
) => {
  return useQuery<Types.NotificationsQuery, TError, TData>(
    variables === undefined ? ["Notifications"] : ["Notifications", variables],
    useFetcher<Types.NotificationsQuery, Types.NotificationsQueryVariables>(
      NotificationsDocument,
    ).bind(null, variables),
    options,
  )
}

useNotificationsQuery.document = NotificationsDocument

useNotificationsQuery.getKey = (
  variables?: Types.NotificationsQueryVariables,
) =>
  variables === undefined ? ["Notifications"] : ["Notifications", variables]

export const MarkNotificationAsReadDocument = `
    mutation MarkNotificationAsRead {
  markNotificationsRead {
    lastReadAt
  }
}
    `

export const useMarkNotificationAsReadMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.MarkNotificationAsReadMutation,
    TError,
    Types.MarkNotificationAsReadMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.MarkNotificationAsReadMutation,
    TError,
    Types.MarkNotificationAsReadMutationVariables,
    TContext
  >(
    ["MarkNotificationAsRead"],
    useFetcher<
      Types.MarkNotificationAsReadMutation,
      Types.MarkNotificationAsReadMutationVariables
    >(MarkNotificationAsReadDocument),
    options,
  )
}

export const OAuthConnectionsDocument = `
    query OAuthConnections {
  currentAccount {
    oAuthConnections {
      edges {
        cursor
        node {
          ...OAuthConnection
          ... on GoogleOAuthConnection {
            ...GoogleOAuthConnection
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
}
    ${OAuthConnectionFragmentDoc}
${GoogleOAuthConnectionFragmentDoc}
${AreaFragmentDoc}
${ColorFragmentDoc}
${IconFragmentDoc}
${CalendarFragmentDoc}
${TimeZoneFragmentDoc}
${CoordinatesFragmentDoc}
${CalendarParticipantFragmentDoc}
${UserFragmentDoc}
${BadgeFragmentDoc}
${CountryFragmentDoc}
${PageInfoFragmentDoc}
${TagFragmentDoc}`

export const useOAuthConnectionsQuery = <
  TData = Types.OAuthConnectionsQuery,
  TError = GraphQLError,
>(
  variables?: Types.OAuthConnectionsQueryVariables,
  options?: UseQueryOptions<Types.OAuthConnectionsQuery, TError, TData>,
) => {
  return useQuery<Types.OAuthConnectionsQuery, TError, TData>(
    variables === undefined
      ? ["OAuthConnections"]
      : ["OAuthConnections", variables],
    useFetcher<
      Types.OAuthConnectionsQuery,
      Types.OAuthConnectionsQueryVariables
    >(OAuthConnectionsDocument).bind(null, variables),
    options,
  )
}

useOAuthConnectionsQuery.document = OAuthConnectionsDocument

useOAuthConnectionsQuery.getKey = (
  variables?: Types.OAuthConnectionsQueryVariables,
) =>
  variables === undefined
    ? ["OAuthConnections"]
    : ["OAuthConnections", variables]

export const GenerateOAuthConnectUrlDocument = `
    mutation GenerateOAuthConnectURL($input: GenerateOAuthConnectUrlInput!) {
  generateOAuthConnectUrl(input: $input) {
    url
  }
}
    `

export const useGenerateOAuthConnectUrlMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.GenerateOAuthConnectUrlMutation,
    TError,
    Types.GenerateOAuthConnectUrlMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.GenerateOAuthConnectUrlMutation,
    TError,
    Types.GenerateOAuthConnectUrlMutationVariables,
    TContext
  >(
    ["GenerateOAuthConnectURL"],
    useFetcher<
      Types.GenerateOAuthConnectUrlMutation,
      Types.GenerateOAuthConnectUrlMutationVariables
    >(GenerateOAuthConnectUrlDocument),
    options,
  )
}

export const SubmitOAuthReturnUrlDocument = `
    mutation SubmitOAuthReturnURL($input: SubmitOAuthConnectReturnUrlInput!) {
  submitOAuthConnectReturnUrl(input: $input) {
    id
  }
}
    `

export const useSubmitOAuthReturnUrlMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.SubmitOAuthReturnUrlMutation,
    TError,
    Types.SubmitOAuthReturnUrlMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.SubmitOAuthReturnUrlMutation,
    TError,
    Types.SubmitOAuthReturnUrlMutationVariables,
    TContext
  >(
    ["SubmitOAuthReturnURL"],
    useFetcher<
      Types.SubmitOAuthReturnUrlMutation,
      Types.SubmitOAuthReturnUrlMutationVariables
    >(SubmitOAuthReturnUrlDocument),
    options,
  )
}

export const UpdateOAuthConnectionDocument = `
    mutation UpdateOAuthConnection($input: UpdateOAuthConnectionInput!) {
  updateOAuthConnection(input: $input) {
    id
  }
}
    `

export const useUpdateOAuthConnectionMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.UpdateOAuthConnectionMutation,
    TError,
    Types.UpdateOAuthConnectionMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.UpdateOAuthConnectionMutation,
    TError,
    Types.UpdateOAuthConnectionMutationVariables,
    TContext
  >(
    ["UpdateOAuthConnection"],
    useFetcher<
      Types.UpdateOAuthConnectionMutation,
      Types.UpdateOAuthConnectionMutationVariables
    >(UpdateOAuthConnectionDocument),
    options,
  )
}

export const DeleteOAuthConnectionDocument = `
    mutation DeleteOAuthConnection($input: DeleteOAuthConnectionInput!) {
  deleteOAuthConnection(input: $input) {
    id
  }
}
    `

export const useDeleteOAuthConnectionMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.DeleteOAuthConnectionMutation,
    TError,
    Types.DeleteOAuthConnectionMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.DeleteOAuthConnectionMutation,
    TError,
    Types.DeleteOAuthConnectionMutationVariables,
    TContext
  >(
    ["DeleteOAuthConnection"],
    useFetcher<
      Types.DeleteOAuthConnectionMutation,
      Types.DeleteOAuthConnectionMutationVariables
    >(DeleteOAuthConnectionDocument),
    options,
  )
}

export const ResyncOAuthConnectionDocument = `
    mutation ResyncOAuthConnection($input: ResyncOAuthConnectionInput!) {
  resyncOAuthConnection(input: $input) {
    id
  }
}
    `

export const useResyncOAuthConnectionMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.ResyncOAuthConnectionMutation,
    TError,
    Types.ResyncOAuthConnectionMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.ResyncOAuthConnectionMutation,
    TError,
    Types.ResyncOAuthConnectionMutationVariables,
    TContext
  >(
    ["ResyncOAuthConnection"],
    useFetcher<
      Types.ResyncOAuthConnectionMutation,
      Types.ResyncOAuthConnectionMutationVariables
    >(ResyncOAuthConnectionDocument),
    options,
  )
}

export const SearchParticipantsDocument = `
    query SearchParticipants($first: Int, $after: String, $last: Int, $before: String, $query: String) {
  searchParticipants(
    first: $first
    after: $after
    last: $last
    before: $before
    query: $query
  ) {
    edges {
      cursor
      node {
        __typename
        ... on User {
          ...User
        }
        ... on Contact {
          ...Contact
        }
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${UserFragmentDoc}
${ColorFragmentDoc}
${TimeZoneFragmentDoc}
${CoordinatesFragmentDoc}
${BadgeFragmentDoc}
${IconFragmentDoc}
${CountryFragmentDoc}
${ContactFragmentDoc}
${PageInfoFragmentDoc}`

export const useSearchParticipantsQuery = <
  TData = Types.SearchParticipantsQuery,
  TError = GraphQLError,
>(
  variables?: Types.SearchParticipantsQueryVariables,
  options?: UseQueryOptions<Types.SearchParticipantsQuery, TError, TData>,
) => {
  return useQuery<Types.SearchParticipantsQuery, TError, TData>(
    variables === undefined
      ? ["SearchParticipants"]
      : ["SearchParticipants", variables],
    useFetcher<
      Types.SearchParticipantsQuery,
      Types.SearchParticipantsQueryVariables
    >(SearchParticipantsDocument).bind(null, variables),
    options,
  )
}

useSearchParticipantsQuery.document = SearchParticipantsDocument

useSearchParticipantsQuery.getKey = (
  variables?: Types.SearchParticipantsQueryVariables,
) =>
  variables === undefined
    ? ["SearchParticipants"]
    : ["SearchParticipants", variables]

export const TagsDocument = `
    query Tags($first: Int, $after: String, $last: Int, $before: String) {
  tags(first: $first, after: $after, last: $last, before: $before) {
    edges {
      node {
        ...Tag
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${TagFragmentDoc}
${ColorFragmentDoc}
${IconFragmentDoc}
${PageInfoFragmentDoc}`

export const useTagsQuery = <TData = Types.TagsQuery, TError = GraphQLError>(
  variables?: Types.TagsQueryVariables,
  options?: UseQueryOptions<Types.TagsQuery, TError, TData>,
) => {
  return useQuery<Types.TagsQuery, TError, TData>(
    variables === undefined ? ["Tags"] : ["Tags", variables],
    useFetcher<Types.TagsQuery, Types.TagsQueryVariables>(TagsDocument).bind(
      null,
      variables,
    ),
    options,
  )
}

useTagsQuery.document = TagsDocument

useTagsQuery.getKey = (variables?: Types.TagsQueryVariables) =>
  variables === undefined ? ["Tags"] : ["Tags", variables]

export const CreateTagDocument = `
    mutation CreateTag($input: CreateTagInput!) {
  createTag: createTag(input: $input) {
    ...Tag
  }
}
    ${TagFragmentDoc}
${ColorFragmentDoc}
${IconFragmentDoc}`

export const useCreateTagMutation = <TError = GraphQLError, TContext = unknown>(
  options?: UseMutationOptions<
    Types.CreateTagMutation,
    TError,
    Types.CreateTagMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.CreateTagMutation,
    TError,
    Types.CreateTagMutationVariables,
    TContext
  >(
    ["CreateTag"],
    useFetcher<Types.CreateTagMutation, Types.CreateTagMutationVariables>(
      CreateTagDocument,
    ),
    options,
  )
}

export const UpdateTagDocument = `
    mutation UpdateTag($input: UpdateTagInput!) {
  updateTag: updateTag(input: $input) {
    ...Tag
  }
}
    ${TagFragmentDoc}
${ColorFragmentDoc}
${IconFragmentDoc}`

export const useUpdateTagMutation = <TError = GraphQLError, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdateTagMutation,
    TError,
    Types.UpdateTagMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.UpdateTagMutation,
    TError,
    Types.UpdateTagMutationVariables,
    TContext
  >(
    ["UpdateTag"],
    useFetcher<Types.UpdateTagMutation, Types.UpdateTagMutationVariables>(
      UpdateTagDocument,
    ),
    options,
  )
}

export const DeleteTagDocument = `
    mutation DeleteTag($input: DeleteTagInput!) {
  deleteTag: deleteTag(input: $input) {
    ...Tag
  }
}
    ${TagFragmentDoc}
${ColorFragmentDoc}
${IconFragmentDoc}`

export const useDeleteTagMutation = <TError = GraphQLError, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DeleteTagMutation,
    TError,
    Types.DeleteTagMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.DeleteTagMutation,
    TError,
    Types.DeleteTagMutationVariables,
    TContext
  >(
    ["DeleteTag"],
    useFetcher<Types.DeleteTagMutation, Types.DeleteTagMutationVariables>(
      DeleteTagDocument,
    ),
    options,
  )
}

export const ReorderTagsDocument = `
    mutation ReorderTags($input: ReorderTagsInput!) {
  reorderTags(input: $input)
}
    `

export const useReorderTagsMutation = <
  TError = GraphQLError,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    Types.ReorderTagsMutation,
    TError,
    Types.ReorderTagsMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    Types.ReorderTagsMutation,
    TError,
    Types.ReorderTagsMutationVariables,
    TContext
  >(
    ["ReorderTags"],
    useFetcher<Types.ReorderTagsMutation, Types.ReorderTagsMutationVariables>(
      ReorderTagsDocument,
    ),
    options,
  )
}

export const UserDocument = `
    query User($id: ID, $username: String) {
  user(id: $id, username: $username) {
    ...User
  }
}
    ${UserFragmentDoc}
${ColorFragmentDoc}
${TimeZoneFragmentDoc}
${CoordinatesFragmentDoc}
${BadgeFragmentDoc}
${IconFragmentDoc}
${CountryFragmentDoc}`

export const useUserQuery = <TData = Types.UserQuery, TError = GraphQLError>(
  variables?: Types.UserQueryVariables,
  options?: UseQueryOptions<Types.UserQuery, TError, TData>,
) => {
  return useQuery<Types.UserQuery, TError, TData>(
    variables === undefined ? ["User"] : ["User", variables],
    useFetcher<Types.UserQuery, Types.UserQueryVariables>(UserDocument).bind(
      null,
      variables,
    ),
    options,
  )
}

useUserQuery.document = UserDocument

useUserQuery.getKey = (variables?: Types.UserQueryVariables) =>
  variables === undefined ? ["User"] : ["User", variables]

export const SearchUsersDocument = `
    query SearchUsers($query: String!) {
  searchUsers(query: $query) {
    edges {
      cursor
      node {
        ...User
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${UserFragmentDoc}
${ColorFragmentDoc}
${TimeZoneFragmentDoc}
${CoordinatesFragmentDoc}
${BadgeFragmentDoc}
${IconFragmentDoc}
${CountryFragmentDoc}
${PageInfoFragmentDoc}`

export const useSearchUsersQuery = <
  TData = Types.SearchUsersQuery,
  TError = GraphQLError,
>(
  variables: Types.SearchUsersQueryVariables,
  options?: UseQueryOptions<Types.SearchUsersQuery, TError, TData>,
) => {
  return useQuery<Types.SearchUsersQuery, TError, TData>(
    ["SearchUsers", variables],
    useFetcher<Types.SearchUsersQuery, Types.SearchUsersQueryVariables>(
      SearchUsersDocument,
    ).bind(null, variables),
    options,
  )
}

useSearchUsersQuery.document = SearchUsersDocument

useSearchUsersQuery.getKey = (variables: Types.SearchUsersQueryVariables) => [
  "SearchUsers",
  variables,
]
